import {useEffect, useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useInView} from "react-intersection-observer";
import Spinner from "../spinner/Spinner";
import deleteBadge from "../../api/deleteBadge";
import ModalIcon from "../../assets/img/dashboard/modal-icon.png";
import useGetAllBadges from "../../hooks/useGetAllBadges";
import BadgeModal from "../badgeModal/BadgeModal";
import {
  AdminCreateBadgeProps,
  HashTagListProps,
} from "../../types/ComponentProps";
import BadgeListStyles from "./BadgeListStyles.module.css";
import BadgeUpdate from "./BadgeUpdate";

const BadgesList = () => {
  const queryClient = useQueryClient();
  const {ref, inView} = useInView();

  const {
    getAllBadgesData,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetAllBadges();
  const [modals, setModals] = useState({
    edit: false,
    delete: false,
    loading: false,
    update: false,
    badge: false,
  });

  const [selectedBadge, setSelectedBadge] = useState<number | null>(null);
  const [editDropdown, setEditDropdown] = useState<number | null>(null);
  const [isBadgeListLoading, setIsBadgeListLoading] = useState<boolean>(false);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const handleModalToggle = (modal: keyof typeof modals) => {
    setModals((prev) => ({...prev, [modal]: !prev[modal]}));
  };

  const deleteBadgeMutation = useMutation({
    mutationFn: (badgeId: number) => deleteBadge(badgeId),
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({queryKey: ["getAllBadges"]});
      } catch (error) {
        console.error("Error invalidating queries:", error);
      } finally {
        handleModalToggle("loading");
        handleModalToggle("delete");
      }
    },
    onError: (error: Error) => {
      console.error("Error deleting badge:", error);
    },
  });

  const handleDeleteBadgeSubmit = () => {
    if (selectedBadge) {
      handleModalToggle("loading");
      deleteBadgeMutation.mutate(selectedBadge);
    }
  };

  const handleEditClick = (badgeId: number) => {
    if (editDropdown === badgeId) {
      setEditDropdown(null);
    } else {
      setEditDropdown(badgeId);
    }
  };

  const handleDeleteModalOpen = (badgeId: number) => {
    setSelectedBadge(badgeId);
    handleModalToggle("delete");
  };

  // Update Badge
  const handleUpdateModalOpen = (badgeId: number) => {
    setSelectedBadge(badgeId);
    handleModalToggle("edit");
  };

  return (
    <>
      {modals.delete && (
        <div className={BadgeListStyles.deletePrompt}>
          <div className={BadgeListStyles.deletePromptMainContainer}>
            <span
              className="fa-solid fa-xmark"
              onClick={() => handleModalToggle("delete")}
            />
            <img
              src={ModalIcon}
              alt="Modal icon"
              className={BadgeListStyles.deleteIcon}
            />
            <h1>Are you sure you want to delete this Badge?</h1>
            <button onClick={handleDeleteBadgeSubmit}>
              {modals.loading ? (
                <Spinner variant="small-spinner" alignSpin="center-spinner" />
              ) : (
                "YES"
              )}
            </button>
          </div>
        </div>
      )}

      {modals.edit && (
        <BadgeUpdate
          selectedBadge={selectedBadge}
          setModals={setModals}
          setIsBadgeListLoading={setIsBadgeListLoading}
        />
      )}

      {modals.badge && (
        <BadgeModal setBadgeModal={() => handleModalToggle("badge")} />
      )}

      <div className={BadgeListStyles.manageBadgesBlk}>
        <div className={BadgeListStyles.manageBadgesBlkTitle}>
          <h1>Manage Badges</h1>
          <button onClick={() => handleModalToggle("badge")}>
            <i className="fa-solid fa-plus"></i>Create New Badge
          </button>
        </div>

        <div className={BadgeListStyles.badgesTableTH}>
          <div>
            <h1>Badges</h1>
          </div>
          <div>
            <h1>Message</h1>
          </div>
          <div>
            <h1>Frequency</h1>
          </div>
          <div>
            <h1>HashTags</h1>
          </div>
          <div>
            <h1>Points</h1>
          </div>
          <div>
            <h1>Actions</h1>
          </div>
        </div>

        <div className={BadgeListStyles.badgeListSectionTable}>
          {isLoading || isBadgeListLoading ? (
            <div style={{display: "block", padding: "20px 0px"}}>
              <Spinner variant="medium-spinner" alignSpin="center-spinner" />
            </div>
          ) : getAllBadgesData?.pages[0]?.badges.length === 0 ? (
            <p className={BadgeListStyles.emptyListMessage}>
              There are no Badges available.
            </p>
          ) : (
            <div className={BadgeListStyles.rowBlkBadgelistContainer}>
              {getAllBadgesData?.pages.map((page) =>
                page.badges.map((badge: AdminCreateBadgeProps) => (
                  <div
                    className={BadgeListStyles.badgeListSectionTableRow}
                    key={badge.badgeId}>
                    <div
                      className={
                        BadgeListStyles.flexBoxBadgeListSectionTableRow
                      }>
                      {badge.imageURL && (
                        <img alt="Badge Icon" src={badge.imageURL} />
                      )}
                      <p>{badge.badgeName}</p>
                    </div>
                    <div>
                      <p>{badge.message}</p>
                    </div>
                    <div style={{paddingLeft: "5px"}}>
                      <p>{badge.frequency}</p>
                    </div>
                    <div
                      className={BadgeListStyles.hashTagListFlexBox}
                      style={{paddingLeft: "7px"}}>
                      {badge.hashTagIds?.map((tag: HashTagListProps) => (
                        <p key={tag.hashTagId}>#{tag.hashTagName}</p>
                      ))}
                    </div>
                    <div style={{paddingLeft: "10px"}}>
                      <p>{badge.points}</p>
                    </div>
                    <div style={{position: "relative", paddingLeft: "15px"}}>
                      {editDropdown === badge.badgeId && (
                        <div
                          className={BadgeListStyles.dropDownEdit}
                          onMouseLeave={() => setEditDropdown(null)}>
                          <div
                            onClick={() =>
                              handleUpdateModalOpen(badge.badgeId ?? 0)
                            }>
                            <span>Edit</span>
                          </div>
                          <div
                            onClick={() =>
                              handleDeleteModalOpen(badge.badgeId ?? 0)
                            }>
                            <span>Delete</span>
                          </div>
                        </div>
                      )}
                      <i
                        onClick={() => handleEditClick(badge.badgeId ?? 0)}
                        className="fa-solid fa-ellipsis"></i>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
          <div ref={ref} style={{height: "1px"}}></div>
          {isFetchingNextPage && (
            <div style={{display: "block", padding: "20px 0px"}}>
              <Spinner variant="medium-spinner" alignSpin="center-spinner" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BadgesList;
