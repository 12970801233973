import SearchStyles from "./Search.module.css";

const Search = () => {
  return (
    <div className={SearchStyles.searchContainer}>
      <i className="fa-solid fa-magnifying-glass"></i>
    </div>
  );
};

export default Search;
