import axiosInstance from "./axiosInstance";

const getUserPoint = async () => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const {data} = await axiosInstance.get("/api/user-points", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (error) {
    throw new Error("Error fetching user points.");
  }
};

export default getUserPoint;
