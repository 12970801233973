import useGetProfilePicture from "../../hooks/useGetProfilePicture";
import {jwtDecode} from "jwt-decode";
import Spinner from "../spinner/Spinner";
import {
  TokenPayload,
  AvatarNotificationProps,
} from "../../types/ComponentProps";
import NavigationDropDown from "../dropDown/NavigationDropDown";
import AvatarStyles from "./Avatar.module.css";
// Image
import DefaultImage from "../../assets/img/default-profile-photo.png";

const Avatar = ({avatarToggle, setAvatarToogle}: AvatarNotificationProps) => {
  const userDetails = jwtDecode<TokenPayload>(
    JSON.stringify(localStorage.getItem("accessTokenGC"))
  );

  const {getProfileImage, error, isLoading, isFetching} = useGetProfilePicture(
    userDetails?.id ?? 0
  );

  const onClickHandler = () => {
    return !setAvatarToogle ? null : setAvatarToogle(!avatarToggle);
  };

  return (
    <div className={AvatarStyles?.avatarContainerBlk} onClick={onClickHandler}>
      {avatarToggle ? <NavigationDropDown /> : null}
      {isLoading || isFetching ? (
        <Spinner
          addedStyles={{marginTop: "6px"}}
          variant="small-spinner"
          alignSpin="center-spinner"
        />
      ) : error ? (
        <img alt="default" src={DefaultImage} />
      ) : (
        <img alt="default" src={getProfileImage?.profileImage} />
      )}
    </div>
  );
};

export default Avatar;
