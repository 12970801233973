import TeamPersonalDetails from "./TeamPersonalDetails";
import ProfileBG from "../../assets/img/dashboard/profile-bg.png";
import TeamBlockStyles from "./TeamBlockStyles.module.css";
import {TeamProfileDetailsProps} from "../../types/ComponentProps";

const TeamProfileDetails = ({
  userId,
  showDetails,
  onMouseEnter,
  onMouseLeave,
}: TeamProfileDetailsProps) => {
  return showDetails ? (
    <div
      className={TeamBlockStyles.teamDetailsSectionBlock}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <img
        className={TeamBlockStyles.backgroundImage}
        alt="background profile"
        src={ProfileBG}
      />
      <div className={TeamBlockStyles.containerSectionBlk}>
        <TeamPersonalDetails userId={userId} />
      </div>
    </div>
  ) : null;
};

export default TeamProfileDetails;
