import axiosInstance from "./axiosInstance";

const getProfilePhoto = async (userId: number) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const {data} = await axiosInstance.get(`/api/profileImage/${userId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (error) {
    throw new Error("Error creating user");
  }
};

export default getProfilePhoto;
