import {useState, useEffect} from "react";
import {
  GetAllUsersProps,
  RecepientBadgeButtonProps,
} from "../../types/ComponentProps";
import useGetAllUsers from "../../hooks/useGetAllUsers";
import BadgeSelectionModalStyles from "./BadgeSelectionModalStyles.module.css";
import RecipientAvatar from "../recipientButton/RecipientAvatar";

const RecepientBadgeButton = ({
  setRecipientList,
}: RecepientBadgeButtonProps) => {
  const {getAllUsersData} = useGetAllUsers();
  const [recipientModal, setRecipientModal] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<GetAllUsersProps[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<GetAllUsersProps[]>([]);

  const onClickShowModalHandler = () => {
    setRecipientModal(true);
  };

  useEffect(() => {
    if (getAllUsersData?.users) {
      const filtered = getAllUsersData.users.filter(
        (user: GetAllUsersProps) =>
          !selectedUsers.some((selected) => selected.id === user.id) &&
          (user?.preferredName
            ?.toLowerCase()
            .includes(searchInput.toLowerCase()) ||
            user.email?.toLowerCase().includes(searchInput.toLowerCase()))
      );
      setFilteredUsers(filtered);
    }

    setRecipientList(selectedUsers);
  }, [searchInput, getAllUsersData, selectedUsers, setRecipientList]);

  const onUserClickHandler = (user: GetAllUsersProps) => {
    if (selectedUsers.some((selected) => selected.id === user.id)) {
      setSelectedUsers((prev) => prev.filter((u) => u.id !== user.id));
    } else {
      setSelectedUsers((prev) => [...prev, user]);
    }
  };

  const onDeleteSelectedUserHandler = (userId: number) => {
    setSelectedUsers((prev) => prev.filter((user) => user.id !== userId));
  };

  return (
    <div className={BadgeSelectionModalStyles.recipientBadgeButtonContainer}>
      <button
        className={BadgeSelectionModalStyles.recipientBadgeButton}
        onClick={onClickShowModalHandler}>
        @ Recipient
      </button>
      {recipientModal && (
        <div
          className={BadgeSelectionModalStyles.dropDownListUsersBlk}
          onMouseLeave={(e) => {
            e.preventDefault();
            setRecipientModal(false);
          }}>
          <div className={BadgeSelectionModalStyles.badgeListContainerBlk}>
            <input
              type="text"
              placeholder="Search User...."
              spellCheck="false"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            {filteredUsers.length === 0 ? (
              <div className={BadgeSelectionModalStyles.noUsersMessage}>
                {getAllUsersData?.users?.length === selectedUsers.length
                  ? "You have selected all the users."
                  : "No users found."}
              </div>
            ) : (
              filteredUsers.map((user, index) => {
                const isSelected = selectedUsers.some(
                  (selected) => selected.id === user.id
                );
                return (
                  <div
                    key={index}
                    className={`${
                      BadgeSelectionModalStyles.badgeRowContainerBlk
                    } ${
                      isSelected ? BadgeSelectionModalStyles.selectedUser : ""
                    }`}
                    onClick={() => onUserClickHandler(user)}>
                    <div>
                      <RecipientAvatar userId={Number(user?.id ?? 0)} />
                    </div>
                    <div>
                      <h2>{user?.preferredName}</h2>
                      <p>{user?.email}</p>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
      {selectedUsers.length > 0 && (
        <div className={BadgeSelectionModalStyles.selectedUsersContainer}>
          {selectedUsers.map((user, index) => (
            <div
              key={index}
              className={BadgeSelectionModalStyles.selectedUserItem}>
              <span>@{user?.preferredName}</span>
              <span
                className="fa-solid fa-xmark"
                onClick={() =>
                  onDeleteSelectedUserHandler(user.id ?? 0)
                }></span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RecepientBadgeButton;
