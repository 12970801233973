import {useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";
import Spinner from "../spinner/Spinner";
import DefaultImage from "../../assets/img/default-profile-photo.png";
import PostCardStyles from "./PostCardStyles.module.css";
import PostCardDropDown from "./PostCardDropDown";
import {
  PostCardHeaderSectionProp,
  AccessTokenProps,
} from "../../types/ComponentProps";
import dateFormatter from "../../utils/dateFormat";
import useGetProfilePicture from "../../hooks/useGetProfilePicture";
import {getLevel} from "../../utils/getLevel";

const PostCardHeader = ({
  userId,
  postId,
  dateCreated,
  preferredName,
  pronouns,
  pointsGiven,
  points,
  badgeId,
  isAdmin,
  setEditCardSelection,
  setEditPostModal,
}: PostCardHeaderSectionProp) => {
  const {isLoading, isFetching, isError, getProfileImage} =
    useGetProfilePicture(userId);
  const accessToken = localStorage.getItem("accessTokenGC");
  const [jwtDecodeToken, setJwtDecodedToken] = useState<AccessTokenProps>(null);

  const levelInfo = getLevel(points?.points ?? 0);
  const levelIndex = levelInfo?.index != null ? levelInfo.index + 1 : null;

  useEffect(() => {
    if (accessToken) {
      try {
        setJwtDecodedToken(jwtDecode(accessToken));
      } catch (error) {
        console.error("Invalid token:", error);
      }
    }
  }, [accessToken]);

  return (
    <>
      <div className={PostCardStyles.headerSectionStyles}>
        {pointsGiven ? (
          <div className={PostCardStyles.pointsGivenPill}>+{pointsGiven}</div>
        ) : null}

        <div className={PostCardStyles.avatarSection}>
          {isLoading && isFetching ? (
            <Spinner
              addedStyles={{marginTop: "6px"}}
              variant="extra-small-spinner"
              alignSpin="center-spinner"
            />
          ) : isError ? (
            <img alt="" src={DefaultImage} />
          ) : badgeId ? null : (
            <img alt="" src={getProfileImage?.profileImage} />
          )}
        </div>

        {badgeId ? null : (
          <div className={PostCardStyles.preferredNameSection}>
            <div className={PostCardStyles.preferredNameSectionFlexBox}>
              {preferredName === "iXie" ? (
                <h3 style={{textTransform: "initial"}}>iXie</h3>
              ) : (
                <h3>{preferredName}</h3>
              )}

              <div className={PostCardStyles.pronounSection}>
                <span>{pronouns ? pronouns : null}</span>
              </div>
            </div>
            {isAdmin ? (
              <div className={PostCardStyles.levelSectionCard}>
                <p>Administrator</p>
              </div>
            ) : (
              <div className={PostCardStyles.levelSectionCard}>
                {points && levelInfo ? (
                  <p>
                    Lv. {levelIndex} - {levelInfo.level}
                  </p>
                ) : null}
              </div>
            )}
          </div>
        )}

        <div
          className={PostCardStyles.dateSection}
          style={preferredName ? {marginLeft: "auto"} : {}}>
          <span>{dateFormatter(dateCreated)}</span>
        </div>

        <div>
          {jwtDecodeToken?.id !== userId && !jwtDecodeToken?.isAdmin ? null : (
            <PostCardDropDown
              postId={postId}
              setEditPostModal={setEditPostModal}
              setEditCardSelection={setEditCardSelection}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PostCardHeader;
