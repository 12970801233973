import AmountModal from "./AmountModal";
import {AmountModalProps} from "../../types/ComponentProps";
import AmountButtonStyles from "./AmoutButtonStyles.module.css";

const AmountButton = ({
  giveablePoints,
  formPost,
  setFormPost,
  amountModal,
  setAmountModal,
}: AmountModalProps) => {
  const onClickHandler = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (setAmountModal) {
      setAmountModal(!amountModal);
    }
  };

  return (
    <div className={AmountButtonStyles.amountButtonContainer}>
      {amountModal ? (
        <AmountModal
          formPost={formPost}
          setFormPost={setFormPost}
          setAmountModal={setAmountModal}
          giveablePoints={giveablePoints}
        />
      ) : null}
      <button
        className={AmountButtonStyles.amountButton}
        onClick={onClickHandler}>
        <i className="fa-solid fa-plus"></i> Amount
      </button>
    </div>
  );
};

export default AmountButton;
