import {useEffect, useRef, useState} from "react";
import {PostBlockProps} from "../../types/ComponentProps";
import PostBlockHeader from "./PostBlockHeader";
import Message from "../message/Message";
import PostBlockTextArea from "./PostBlockTextArea";
import Spinner from "../spinner/Spinner";
import PostBlockStyles from "./PostBlockStyles.module.css";

const PostBlock = ({
  onChangeHandler,
  onSubmitHandler,
  postLoading,
  data,
  formPost,
  setFormPost,
  filteredUsers,
  setFilteredUsers,
  emptyValidation,
  setEmptyValidation,
  emptyPoints,
  setEmptyPoints,
  exceedPointsValidation,
  setExceedPointsValidation,
  selectedHashTag,
  setSelectedHashTag,
  imageValidation,
  setImageValidation,
}: PostBlockProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  useEffect(() => {
    if (formPost?.imagePost) {
      const imageUrl = URL.createObjectURL(formPost.imagePost);
      setImagePreview(imageUrl);
      return () => URL.revokeObjectURL(imageUrl);
    }

    if (formPost?.pointsGiven && formPost?.recipients) {
      if (setEmptyPoints) {
        setEmptyPoints(false);
      }
      if (setExceedPointsValidation) {
        setExceedPointsValidation(false);
      }
    }
    setImagePreview(null);
  }, [
    formPost?.imagePost,
    formPost?.pointsGiven,
    formPost?.recipients,
    formPost?.hashTagIds,
    setEmptyPoints,
    setExceedPointsValidation,
    setEmptyValidation,
  ]);
  useEffect(() => {
    if (imageValidation) {
      setEmptyValidation?.(false);
      if (emptyPoints) {
        setEmptyPoints?.(true);
        setImageValidation?.(null);
      }
      if (exceedPointsValidation) {
        setExceedPointsValidation?.(true);
        setImageValidation?.(null);
      }
    } else if (emptyValidation) {
      setImageValidation?.(null);
    }
  }, [
    imageValidation,
    emptyValidation,
    emptyPoints,
    exceedPointsValidation,
    setEmptyPoints,
    setExceedPointsValidation,
    setEmptyValidation,
    setImageValidation,
  ]);

  const imageUploadHandler = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSizeInMB = 2; // Set maximum size in MB
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert to bytes

      if (validImageTypes.includes(file.type)) {
        if (file.size <= maxSizeInBytes) {
          // Check if file size is within limit
          if (setFormPost) {
            setFormPost((previous) => ({
              ...previous,
              imagePost: file,
            }));
          }
          const imageUrl = URL.createObjectURL(file);
          setImagePreview(imageUrl);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          if (setImageValidation) {
            setImageValidation(null);
          }
          if (setEmptyValidation) {
            setEmptyValidation(false);
          }
          if (setExceedPointsValidation) {
            setExceedPointsValidation(false);
          }
          if (setEmptyPoints) {
            setEmptyPoints(false);
          }
          return () => URL.revokeObjectURL(imageUrl);
        } else {
          // Handle case where file size exceeds 2MB
          if (setImageValidation) {
            setImageValidation(
              "File size exceeds 2MB limit. Please upload a smaller image."
            );
          }
          // Reset image state
          if (setFormPost) {
            setFormPost((previous) => ({
              ...previous,
              imagePost: null,
            }));
          }
          setImagePreview(null);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }
      } else {
        // Handle invalid file type
        if (setFormPost) {
          setFormPost((previous) => ({
            ...previous,
            imagePost: null,
          }));
        }
        setImagePreview(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        if (setImageValidation) {
          setImageValidation(
            "Invalid file type. Please upload a valid image file (JPG, PNG, GIF)."
          );
        }
      }
    }
  };

  const closeImagePreviewClickHandler = () => {
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    if (setFormPost) {
      setFormPost((previous) => ({
        ...previous,
        imagePost: null,
      }));
    }
  };

  const chooseGIFHandler = () => {
    // alert("GIF!");
  };

  const chooseSmileyHandler = () => {
    // alert("SMILEY");
  };

  const closeMessageValidation = () => {
    setEmptyValidation?.(false);
    setImageValidation?.(null);
    setExceedPointsValidation?.(false);
    setEmptyPoints?.(false);
  };

  return (
    <div className={PostBlockStyles.mainContainer}>
      <form onSubmit={onSubmitHandler} encType="multipart/form-data">
        <PostBlockHeader
          formPost={formPost}
          setFormPost={setFormPost}
          data={data}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          selectedHashTag={selectedHashTag}
          setSelectedHashTag={setSelectedHashTag}
        />

        <PostBlockTextArea
          onChangeHandler={onChangeHandler}
          formPost={formPost}
          setFormPost={setFormPost}
          selectedHashTag={selectedHashTag}
          setSelectedHashTag={setSelectedHashTag}
        />

        {emptyValidation ? (
          <Message variant="error">
            Status or Image upload is required to post.
            <i
              onClick={closeMessageValidation}
              className="fa-solid fa-xmark"></i>
          </Message>
        ) : null}
        {imageValidation ? (
          <Message variant="error">
            {imageValidation}
            <i
              onClick={closeMessageValidation}
              className="fa-solid fa-xmark"></i>
          </Message>
        ) : null}
        {emptyPoints ? (
          <Message variant="error">
            Recipient and Amount fields are required if either one has a value.
            <i
              onClick={closeMessageValidation}
              className="fa-solid fa-xmark"></i>
          </Message>
        ) : null}
        {exceedPointsValidation ? (
          <Message variant="error">
            You cannot give away more points than available.
            <i
              onClick={closeMessageValidation}
              className="fa-solid fa-xmark"></i>
          </Message>
        ) : null}

        <div className={PostBlockStyles.footerBlock}>
          <div className="post-icon-blk">
            <i
              className="fa-regular fa-face-smile"
              onClick={chooseSmileyHandler}></i>
            <span onClick={chooseGIFHandler}>GIF</span>
            <i className="fa-regular fa-image" onClick={imageUploadHandler}></i>
            <input
              type="file"
              ref={fileInputRef}
              name="imagePost"
              style={{display: "none"}}
              onChange={handleFileChange}
            />
          </div>

          <div>
            <button className={PostBlockStyles.postButton} type="submit">
              {postLoading ? (
                <Spinner alignSpin="center-spinner" variant="small-spinner" />
              ) : (
                <>
                  <span>Post</span>
                  <i className="fa-solid fa-arrow-right"></i>
                </>
              )}
            </button>
          </div>
        </div>
      </form>
      {imagePreview && (
        <div className={PostBlockStyles.imagePreviewContainer}>
          <div
            className={PostBlockStyles.closeImagePreview}
            onClick={closeImagePreviewClickHandler}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          <img
            src={imagePreview}
            alt="Preview"
            className={PostBlockStyles.imagePreview}
          />
        </div>
      )}
    </div>
  );
};

export default PostBlock;
