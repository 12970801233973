import React, {useState, useRef, useEffect} from "react";
import {
  CustomSelectOption,
  CustomSelectProps,
} from "../../types/ComponentProps";
import CustomSelectStyles from "./CustomSelectStyles.module.css";

const CustomSelect = ({options, onSelect, placeholder}: CustomSelectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] =
    useState<CustomSelectOption | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleSelectOption = (option: CustomSelectOption) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={CustomSelectStyles.customSelect} ref={dropdownRef}>
      <div
        className={CustomSelectStyles.selectDisplay}
        onClick={toggleDropdown}>
        <span>{selectedOption ? selectedOption.label : placeholder}</span>
        <i
          className={`${
            isOpen
              ? `fa-solid fa-chevron-up ${CustomSelectStyles.icon}`
              : `fa-solid fa-chevron-down ${CustomSelectStyles.icon}`
          }`}
        />
      </div>
      {isOpen && (
        <ul className={CustomSelectStyles.optionList}>
          {options.map((option) => (
            <li
              key={option.value}
              className={CustomSelectStyles.optionItem}
              onClick={() => handleSelectOption(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
