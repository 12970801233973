import {Link} from "react-router-dom";
import BlockStyles from "./BlockStyles.module.css";

type BlockHeaderProps = {
  navigation: "dots" | "viewall" | null | undefined;
  linkURL?: string;
};

export const BlockHeader = ({navigation, linkURL}: BlockHeaderProps) => {
  const navigationMap = {
    viewall: (
      <Link to={linkURL || "/"} className={BlockStyles.viewAllLink}>
        View all
      </Link>
    ),
    dots: (
      <Link to="/" className={BlockStyles.dots}>
        <i className="fa-solid fa-ellipsis"></i>
      </Link>
    ),
  };

  return navigation ? navigationMap[navigation] : null;
};

export default BlockHeader;
