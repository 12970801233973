import axiosInstance from "./axiosInstance";
import {CreatePostType} from "../types/ComponentProps";

const createPost = async (formData: CreatePostType) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const data = await axiosInstance.post("/api/posts", formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    throw new Error("Error creating user");
  }
};

export default createPost;
