import PersonalDetails from "./PersonalDetails";
import MyProfileStyles from "./MyProfileStyles.module.css";
import ProfileTab from "../profileTab/ProfileTab";

const MyProfile = () => {
  return (
    <div className={MyProfileStyles.myProfileContainerSection}>
      <div>
        <PersonalDetails />
      </div>
      <div>
        <ProfileTab />
      </div>
    </div>
  );
};

export default MyProfile;
