import {RecognitionBlockProps} from "../../types/ComponentProps";
import CometImage from "../../assets/img/dashboard/comet-image.png";
import SpecialRecognitionStyles from "./SpecialRecognitionStyles.module.css";

const SpecialRecognition = ({setModalRecognition}: RecognitionBlockProps) => {
  const onClickHandler = () => {
    if (setModalRecognition) {
      setModalRecognition(true);
    }
  };

  return (
    <div className={SpecialRecognitionStyles.specialAwardSectionBlk}>
      <div
        className={SpecialRecognitionStyles.specialAwardDescriptionSectionBlk}>
        <img
          alt="Comet"
          src={CometImage}
          className={SpecialRecognitionStyles.cometImage}
        />
        <h1>Special Award</h1>
        <p>Make sure to recognize the real stars</p>
        <button onClick={onClickHandler}>Give special recognition</button>
      </div>
    </div>
  );
};

export default SpecialRecognition;
