import {useQuery} from "@tanstack/react-query";
import getUserDetailsById from "../api/getUserDetailsById";

const useGetUserDetailsById = (userId: number) => {
  const {
    data: userDetailsById,
    isLoading,
    isError,
    error,
    isFetching,
    refetch: refetchGetUserDetailsById,
  } = useQuery({
    queryKey: ["getUserDetailsById", userId],
    queryFn: () => getUserDetailsById(userId),
    enabled: userId > 0,
  });
  return {
    isLoading,
    userDetailsById,
    refetchGetUserDetailsById,
    isFetching,
    isError,
    error,
  };
};

export default useGetUserDetailsById;
