import axiosInstance from "./axiosInstance";

const getLeaderBoardList = async (pageParam: number = 1) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const {data} = await axiosInstance.get(
      `/api/leaderboard/?page=${pageParam}&limit=10`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return {
      user: data.users,
      nextPage: pageParam + 1,
      totalPages: data.pagination.totalPages,
    };
  } catch (error) {
    throw new Error("Error fetching user points.");
  }
};

export default getLeaderBoardList;
