import {useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";
import {useQueryClient, useMutation} from "@tanstack/react-query";
import createPost from "../../api/createPost";
import Spinner from "../spinner/Spinner";
import RecipientBadgeButton from "./RecepientBadgeButton";
import {StepTwoProps, TokenPayload} from "../../types/ComponentProps";
import BadgeSelectionModalStyles from "./BadgeSelectionModalStyles.module.css";
import Message from "../message/Message";

const StepTwo = ({
  selectedBadge,
  onCloseClickHandler,
  setSelectBadgeStepOne,
  setGiveBadgeStepTwo,
  setModalRecognition,
  socket,
}: StepTwoProps) => {
  const queryClient = useQueryClient();
  const [recipientsList, setRecipientList] = useState([]);
  const [formBadge, setFormBadge] = useState<any>([]);
  const [isPostLoading, setIsPostLoading] = useState<boolean>(false);
  const [validationStepTwo, setValidationStepTwo] = useState(false);
  const userDetails = jwtDecode<TokenPayload>(
    JSON.stringify(localStorage.getItem("accessTokenGC"))
  );
  const onClickBackHandler = () => {
    if (setSelectBadgeStepOne && setGiveBadgeStepTwo) {
      setGiveBadgeStepTwo(false);
      setSelectBadgeStepOne(true);
    }
  };

  useEffect(() => {
    if (selectedBadge) {
      setFormBadge({
        message: `${selectedBadge.message}`,
        pointsGiven: selectedBadge.points,
        hashTagIds: selectedBadge?.hashTagIds,
        recipients: recipientsList,
        badgeId: selectedBadge?.badgeId,
        badges: [
          {
            badgeId: selectedBadge?.badgeId,
          },
        ],
      });
    }
  }, [selectedBadge, recipientsList]);

  const sendNotificationOnCreateBadgePost = () => {
    socket?.emit("sendMentionBadgeNotification", {
      senderDetails: userDetails,
      receiverDetails: recipientsList,
    });
  };

  useEffect(() => {
    return setValidationStepTwo(false);
  }, [recipientsList]);

  const createPostMutation = useMutation({
    mutationFn: createPost,
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({queryKey: ["getAllPosts"]});
        await queryClient.invalidateQueries({
          queryKey: ["getLeaderBoardList"],
        });
        if (setModalRecognition) {
          setModalRecognition(false);
        }
      } catch (error) {
        console.error("Error invalidating queries:", error);
      } finally {
        setIsPostLoading(false);
      }
    },
    onError: (error: Error) => {
      console.error("Error creating Post:", error);
    },
  });

  const onSubmitHandler = async () => {
    if (!recipientsList.length) {
      return setValidationStepTwo(true);
    }
    setIsPostLoading(true);
    try {
      await createPostMutation.mutateAsync(formBadge);
      sendNotificationOnCreateBadgePost();
    } catch (error) {
      console.error("Error creating Post:", error);
    }
  };

  return (
    <div className={BadgeSelectionModalStyles.stepTwoContainer}>
      <div className={BadgeSelectionModalStyles.flexBoxBlk}>
        <div className={BadgeSelectionModalStyles.stepTwoContainerHeaderBlk}>
          <i
            onClick={onClickBackHandler}
            className="fa-solid fa-chevron-left"></i>
          <h1>Give Badge</h1>
        </div>

        <div>
          <span
            className={`fa-solid fa-xmark ${BadgeSelectionModalStyles.closeIconBadge}`}
            onClick={onCloseClickHandler}></span>
        </div>
      </div>
      <hr className={BadgeSelectionModalStyles.horizontalLine} />
      <div className={BadgeSelectionModalStyles.scrollablleDivBlk}>
        <div className={BadgeSelectionModalStyles.titleSectionMainBlk}>
          <h1>{selectedBadge?.badgeName}</h1>
          <p>+{selectedBadge?.points}</p>
        </div>
        <div className={BadgeSelectionModalStyles.titleSectionMainBlk}>
          <RecipientBadgeButton setRecipientList={setRecipientList} />
        </div>
        <div className={BadgeSelectionModalStyles.mainSectionGiveBadge}>
          <p>
            +{selectedBadge?.points} {selectedBadge?.message}
          </p>
        </div>
      </div>
      <hr className={BadgeSelectionModalStyles.horizontalLine} />
      {validationStepTwo ? (
        <div style={{marginTop: "10px"}}>
          <Message variant="error">Selection of recipients is required</Message>
        </div>
      ) : null}
      <div className={BadgeSelectionModalStyles.givePointsButton}>
        <button onClick={onSubmitHandler}>
          {isPostLoading ? (
            <Spinner variant="small-spinner" alignSpin="center-spinner" />
          ) : (
            <>
              Give {selectedBadge?.points} Points{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default StepTwo;
