import {Link} from "react-router-dom";
import {LogoProps} from "../../types/ComponentProps";
import LogoStyles from "./Logo.module.css";
import NavLogo from "../../assets/img/dashboard/game-changer-logo.png";

const Logo = ({addClassName}: LogoProps) => {
  return (
    <div
      className={`${LogoStyles.logoContainerBlk} ${
        addClassName ? addClassName : null
      }`}>
      <Link to="/">
        <img alt="logo" src={NavLogo} />
      </Link>
    </div>
  );
};

export default Logo;
