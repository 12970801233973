import axiosInstance from "./axiosInstance";

const getPostById = async (postId: number) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const {data} = await axiosInstance.get(`/api/post/${postId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (error) {
    throw new Error("Error fetching user points.");
  }
};

export default getPostById;
