import {Link} from "react-router-dom";
import HashTagIcon from "../../assets/img/navicons/hashTag-icon.png";
import RecognitionImage from "../../assets/img/navicons/recognition-icon.png";
import Logo from "../../components/logo/Logo";
import HashTagList from "../../components/hashTagList/HashTagList";

const AdminCreateHashTag = () => {
  return (
    <>
      <div className="inside-page-container-blk">
        <div className="create-badge-main-container">
          <div className="navlinks-create-badge">
            <Logo addClassName="add-margin-logo" />
            <div className="create-badge-link">
              <Link to="/create-badge">
                <div className="flex-box-blk">
                  <img src={RecognitionImage} alt="Recognition" />
                  <p>Manage Badges</p>
                </div>
              </Link>
            </div>

            <div className="create-badge-link active-nav-admin">
              <Link to="/create-hashTag">
                <div className="flex-box-blk">
                  <img src={HashTagIcon} alt="User" />
                  <p>Manage HashTags</p>
                </div>
              </Link>
            </div>
          </div>
          <div>
            <HashTagList />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCreateHashTag;
