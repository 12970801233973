import {Link} from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import {TokenPayload} from "../../types/ComponentProps";

import AdminLinksStyles from "./AdminLinks.module.css";

const AdminLinks = () => {
  const userDetails = jwtDecode<TokenPayload>(
    JSON.stringify(localStorage.getItem("accessTokenGC"))
  );
  return (
    <div>
      <div className={`flex-box-blk ${AdminLinksStyles.flexBoxBlk}`}>
        <Link to="/rewards">Rewards</Link>
        <Link to="/careers">Careers</Link>
        {userDetails?.isAdmin ? (
          <Link to="/create-badge">
            Admin<i className="fa-solid fa-chevron-down"></i>
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default AdminLinks;
