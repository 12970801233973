import {useMsal} from "@azure/msal-react";
import NavigationDropDownStyles from "./NavigationDropDownStyles.module.css";
import {Link} from "react-router-dom";

const NavigationDropDown = () => {
  const {instance} = useMsal();

  const logoutHandler = async () => {
    try {
      await instance.logoutRedirect({
        onRedirectNavigate: () => {
          localStorage.removeItem("accessTokenGC");
          return true;
        },
      });
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  return (
    <div className={NavigationDropDownStyles.containerBlock}>
      <div className={NavigationDropDownStyles.flexBoxBlk}>
        <Link className={NavigationDropDownStyles.profile} to="/profile">
          My Profile
        </Link>
        <p
          className={NavigationDropDownStyles.signoutText}
          onClick={logoutHandler}>
          Logout
        </p>
      </div>
    </div>
  );
};

export default NavigationDropDown;
