import {useState} from "react";
import RecipientButton from "../recipientButton/RecipientButton";
import AmountButton from "../amountButton/AmountButton";
import HashTagButton from "../hashTagButton/HashTagButton";
import {PostBlockProps} from "../../types/ComponentProps";
import PostBlockStyles from "./PostBlockStyles.module.css";

const PostBlockHeader = ({
  formPost,
  setFormPost,
  data,
  filteredUsers,
  setFilteredUsers,
  selectedHashTag,
  setSelectedHashTag,
}: PostBlockProps) => {
  const [recipientModal, setRecipientModal] = useState<boolean>(false);
  const [amountModal, setAmountModal] = useState<boolean>(false);
  const [hashTagModal, setHashTagModal] = useState<boolean>(false);

  const toggleRecipientModal = () => {
    setRecipientModal(!recipientModal);
    setAmountModal(false);
    setHashTagModal(false);
  };

  const toggleAmountModal = () => {
    setAmountModal(!amountModal);
    setRecipientModal(false);
    setHashTagModal(false);
  };

  const toggleHashTagModal = () => {
    setHashTagModal(!hashTagModal);
    setRecipientModal(false);
    setAmountModal(false);
  };

  const onRemoveRecipient = (index: number) => {
    if (formPost?.recipients) {
      const updatedRecipients = formPost.recipients.filter(
        (_, i) => i !== index
      );

      if (setFormPost) {
        setFormPost({
          ...formPost,
          recipients: updatedRecipients,
        });
      }

      if (setFilteredUsers && filteredUsers) {
        const updatedFilteredUsers = filteredUsers.filter(
          (_, i) => i !== index
        );
        setFilteredUsers(updatedFilteredUsers);
      }
    }
  };

  return (
    <>
      <div className={PostBlockStyles.headerBlock}>
        <div>
          <RecipientButton
            formPost={formPost}
            recipientModal={recipientModal}
            setFormPost={setFormPost}
            setRecipientModal={toggleRecipientModal}
            filteredUsers={filteredUsers}
            setFilteredUsers={setFilteredUsers}
          />
        </div>
        <div>
          <AmountButton
            formPost={formPost}
            amountModal={amountModal}
            setFormPost={setFormPost}
            setAmountModal={toggleAmountModal}
            giveablePoints={data?.points?.giveablePoints}
          />
        </div>
        <div>
          <HashTagButton
            formPost={formPost}
            setFormPost={setFormPost}
            hashTagModal={hashTagModal}
            setHashTagModal={toggleHashTagModal}
            selectedHashTag={selectedHashTag}
            setSelectedHashTag={setSelectedHashTag}
          />
        </div>
      </div>

      <div className={PostBlockStyles.selectedPillContainer}>
        <div>
          <div>
            {formPost?.pointsGiven === 0 ? null : (
              <p className={PostBlockStyles.pointsParagraph}>
                Giving away <span>{formPost?.pointsGiven}</span> points to
              </p>
            )}
          </div>
        </div>
        {formPost?.recipients?.map((data, index) => {
          return (
            <div key={index} className={PostBlockStyles.selectedPillTabBlk}>
              <p key={index}>@{data?.firstName}</p>
              <span
                onClick={() => onRemoveRecipient(index)}
                className="fa-solid fa-xmark"></span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PostBlockHeader;
