import useGetAllPostLikes from "../../hooks/useGetAllPostLikes";
import {LikesModalProps, LikesDataProps} from "../../types/ComponentProps";
import Spinner from "../spinner/Spinner";
import LikesModalStyles from "./LikesModalStyles.module.css";
import AvatarLikesModal from "./AvatarLikesModal";

const LikesModal = ({post, setSelectedCardModal}: LikesModalProps) => {
  const {likes, isLoading} = useGetAllPostLikes(Number(post?.postId));

  const onCloseClick = () => {
    if (setSelectedCardModal) {
      setSelectedCardModal(false);
    }
  };

  return (
    <div className={LikesModalStyles.likesModalContainer}>
      <div className={LikesModalStyles.likesModalMain}>
        <i className="fa-solid fa-xmark" onClick={onCloseClick}></i>
        <h1>Likes</h1>
        <div className={LikesModalStyles.container}>
          {isLoading ? (
            <Spinner variant="medium-spinner" alignSpin="center-spinner" />
          ) : (
            likes?.map((data: LikesDataProps, index: number) => {
              return (
                <div key={index} className={LikesModalStyles.flexBoxBlk}>
                  <div className={LikesModalStyles.avatarSectionBlk}>
                    <AvatarLikesModal userId={data?.id} />
                  </div>
                  <div>
                    <div className={LikesModalStyles.flexBoxBlkInside}>
                      <h3>
                        {data?.firstName} {data.lastName}
                      </h3>
                      <span>{data?.user_profile?.pronouns}</span>
                    </div>
                    <p>{data?.email}</p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default LikesModal;
