import {useState, useRef, useEffect} from "react";
import {useInView} from "react-intersection-observer";
import Spinner from "../spinner/Spinner";
import {
  CustomSelectHashTagType,
  CustomSelectHashTagProps,
} from "../../types/ComponentProps";
import CustomSelectHashTagStyles from "./CustomSelectHashTagStyles.module.css";

const CustomSelectHashTag = ({
  options,
  onSelect,
  placeholder,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
}: CustomSelectHashTagProps) => {
  const {ref, inView} = useInView();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleSelectOption = (option: CustomSelectHashTagType) => {
    setIsOpen(false);
    if (onSelect) {
      onSelect(option);
    }
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (inView && hasNextPage && fetchNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  return (
    <div className={CustomSelectHashTagStyles.customSelect} ref={dropdownRef}>
      <div
        className={CustomSelectHashTagStyles.selectDisplay}
        onClick={toggleDropdown}>
        <span>
          {options?.length === 0 ? "No HashTags available" : placeholder}
        </span>
        <i
          className={`${
            isOpen
              ? `fa-solid fa-chevron-up ${CustomSelectHashTagStyles.icon}`
              : `fa-solid fa-chevron-down ${CustomSelectHashTagStyles.icon}`
          }`}
        />
      </div>
      {isOpen && (
        <ul className={CustomSelectHashTagStyles.optionList}>
          {options?.map((option, index) => (
            <li
              key={index}
              className={CustomSelectHashTagStyles.optionItem}
              onClick={() => handleSelectOption(option)}>
              {option?.hashTagName}
            </li>
          ))}
          <div ref={ref} style={{height: "5px"}}></div>
          {isFetchingNextPage && (
            <div style={{display: "block", padding: "20px 0px"}}>
              <Spinner variant="medium-spinner" alignSpin="center-spinner" />
            </div>
          )}
        </ul>
      )}
    </div>
  );
};

export default CustomSelectHashTag;
