import {LevelName, LevelInfo} from "../types/ComponentProps";

const levels = [
  {level: LevelName.AsteroidAdventurer, min: 0, max: 349, percentage: 10},
  {level: LevelName.CosmicCatalyst, min: 350, max: 899, percentage: 15},
  {
    level: LevelName.ExtraterrestrialExplorer,
    min: 900,
    max: 1799,
    percentage: 20,
  },
  {level: LevelName.FusionForerunner, min: 1800, max: 3499, percentage: 25},
  {
    level: LevelName.GalacticGroundbreaker,
    min: 3500,
    max: 5499,
    percentage: 30,
  },
  {
    level: LevelName.InterplanetaryIconoclast,
    min: 5500,
    max: 7999,
    percentage: 35,
  },
  {
    level: LevelName.InterstellarInitiator,
    min: 8000,
    max: 11999,
    percentage: 40,
  },
  {
    level: LevelName.IntergalacticInnovator,
    min: 12000,
    max: 17999,
    percentage: 45,
  },
  {
    level: LevelName.IntercelestialInventor,
    min: 18000,
    max: 22999,
    percentage: 50,
  },
  {level: LevelName.LunarLeader, min: 23000, max: 27999, percentage: 55},
  {level: LevelName.LightyearLuminary, min: 28000, max: 34999, percentage: 60},
  {level: LevelName.NebularNavigator, min: 35000, max: 44999, percentage: 65},
  {
    level: LevelName.PlanetaryPathfinder,
    min: 45000,
    max: 59999,
    percentage: 70,
  },
  {level: LevelName.PulsarPioneer, min: 60000, max: 74999, percentage: 75},
  {
    level: LevelName.StardustTrailblazer,
    min: 75000,
    max: 92999,
    percentage: 80,
  },
  {
    level: LevelName.StellarTrendsetter,
    min: 93000,
    max: 99999,
    percentage: 85,
  },
  {
    level: LevelName.SupernovaVanguard,
    min: 100000,
    max: 100599,
    percentage: 90,
  },
  {
    level: LevelName.UltravioletVisionary,
    min: 100600,
    max: 101199,
    percentage: 95,
  },
  {level: LevelName.ZeroGWayfarer, min: 101200, max: 999999, percentage: 100},
];

export const getLevel = (points: number): LevelInfo | null => {
  for (let i = 0; i < levels.length; i++) {
    const {level, min, max, percentage} = levels[i];
    if (points >= min && points <= max) {
      return {level, percentage, index: i};
    }
  }
  return {level: null, percentage: null, index: null};
};
