import {useInfiniteQuery} from "@tanstack/react-query";
import getAllMentionedPost from "../api/getAllMentionedPost";

const useGetAllMentionedPost = (userId: number) => {
  const {
    data: allMentionedList,
    error,
    isFetchingNextPage,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    refetch: refetchAllMentionedPost,
  } = useInfiniteQuery({
    queryKey: ["allMentionedPost", userId],
    queryFn: ({pageParam = 1}) => getAllMentionedPost(userId, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage <= lastPage.totalPages
        ? lastPage.nextPage
        : undefined;
    },
    refetchOnWindowFocus: false,
  });

  return {
    error,
    isFetchingNextPage,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    allMentionedList,
    refetchAllMentionedPost,
  };
};

export default useGetAllMentionedPost;
