import {useEffect, useState} from "react";
import Spinner from "../spinner/Spinner";
import useGetUserDetailsById from "../../hooks/useGetUserDetailsById";
import useGetMultipleBadgesByIds from "../../hooks/useGetMultipleBadgesById";
import useGetProfilePicture from "../../hooks/useGetProfilePicture";
import LevelBlock from "../level/LevelBlock";
import DefaultImage from "../../assets/img/default-profile-photo.png";
import TeamBlockStyles from "./TeamBlockStyles.module.css";
import {
  TeamPersonalDetailsProps,
  PostCardDetailsProps,
  BadgePropsDetails,
  SelectedUserPersonalDetailsProps,
} from "../../types/ComponentProps";

const TeamPersonalDetails = ({userId}: TeamPersonalDetailsProps) => {
  const [selectedUser, setSelectedUser] =
    useState<SelectedUserPersonalDetailsProps>({
      user: {},
      profileImage: null,
    });

  const [badgeIds, setBadgeIds] = useState<number[]>([]);
  const {multipleBadgesData, isLoading: badgesLoading} =
    useGetMultipleBadgesByIds(badgeIds);

  const {
    userDetailsById,
    isLoading: detailsLoading,
    isFetching: detailsFetching,
    refetchGetUserDetailsById,
  } = useGetUserDetailsById(userId ?? 0);

  const {
    getProfileImage,
    isLoading: profileLoading,
    isFetching: profileFetching,
  } = useGetProfilePicture(userId ?? 0);

  useEffect(() => {
    if (userId) refetchGetUserDetailsById();
  }, [userId, refetchGetUserDetailsById]);

  useEffect(() => {
    if (userDetailsById || getProfileImage) {
      setSelectedUser({
        user: userDetailsById?.user,
        profileImage: getProfileImage?.profileImage,
      });
    }
  }, [userDetailsById, getProfileImage]);

  useEffect(() => {
    const updatedBadges =
      userDetailsById?.user?.user_profile?.badges?.map(
        (item: PostCardDetailsProps) => item?.badgeId
      ) || [];
    setBadgeIds(updatedBadges);
  }, [userDetailsById?.user?.user_profile?.badges]);

  const isLoading =
    profileLoading || detailsLoading || profileFetching || detailsFetching;

  const splitString = (str: string) =>
    str.length > 30 ? `${str.substring(0, 30)}...` : str;

  const points = selectedUser?.user?.user_point?.pointsId
    ? {
        success: true,
        points: {
          pointsId: selectedUser.user.user_point.pointsId ?? 0,
          userId: selectedUser.user.user_point.userId ?? userId ?? 0,
          points: selectedUser.user.user_point.points ?? 0,
          giveablePoints: selectedUser.user.user_point.giveablePoints ?? 0,
        },
      }
    : null;

  if (isLoading || !selectedUser?.profileImage || !selectedUser?.user) {
    return <Spinner variant="medium-spinner" alignSpin="center-spinner" />;
  }

  return (
    <div className={TeamBlockStyles.personalDetailsMainContainer}>
      <div className={TeamBlockStyles.personalDetailsSectionBlk}>
        <div className={TeamBlockStyles.headerPersonalDetailsSectionBlk}>
          <img
            className={TeamBlockStyles.avatarImageProfileSection}
            alt="profile"
            src={selectedUser?.profileImage ?? DefaultImage}
          />
        </div>
        <div className={TeamBlockStyles.personalDetailsContactSection}>
          <div className={TeamBlockStyles.nameSectionBlk}>
            <h1>{`${selectedUser?.user?.firstName} ${selectedUser?.user?.lastName}`}</h1>
          </div>
          <span className={TeamBlockStyles.jobTitleSectionBlk}>
            {splitString(selectedUser?.user?.jobTitle ?? "")}
          </span>
          <p>{userDetailsById?.user?.email}</p>
          {selectedUser?.user?.mobilePhone && (
            <p>+63{selectedUser.user.mobilePhone}</p>
          )}
        </div>
        {selectedUser?.user?.user_profile?.bio && (
          <>
            <hr />
            <div className={TeamBlockStyles.personalDetailsBioSection}>
              <p>{selectedUser.user.user_profile.bio}</p>
            </div>
          </>
        )}
      </div>
      <div className={TeamBlockStyles.levelSectionBlk}>
        <div className={TeamBlockStyles.mainLevelSectionBlk}>
          {points && <LevelBlock data={points} />}
        </div>
      </div>
      <div className={TeamBlockStyles.containerBadgesSection}>
        {badgesLoading ? (
          <Spinner variant="medium-spinner" alignSpin="center-spinner" />
        ) : Array.isArray(multipleBadgesData?.badges) &&
          multipleBadgesData?.badges.length > 0 ? (
          multipleBadgesData?.badges.map(
            (data: BadgePropsDetails, index: number) => (
              <div key={index} className={TeamBlockStyles.badgeContainerList}>
                <div>
                  <img alt="Badge" src={data?.badgeImage} />
                </div>
                <div>
                  <h2>{data?.badgeName}</h2>
                </div>
              </div>
            )
          )
        ) : (
          <p
            style={{
              marginTop: "10px",
              display: "block",
              color: "#FECF0C",
            }}>
            No badges available
          </p>
        )}
      </div>
    </div>
  );
};

export default TeamPersonalDetails;
