import axiosInstance from "./axiosInstance";

const getPostCommentsById = async (postId: number, pageParam: number = 1) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const {data} = await axiosInstance.get(
      `/api/getPostCommentsById/${postId}?page=${pageParam}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw new Error("Error fetching user points.");
  }
};

export default getPostCommentsById;
