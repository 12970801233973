import {useState} from "react";
import {
  RecognitionBlockProps,
  AdminCreateBadgeProps,
} from "../../types/ComponentProps";
import BadgeSelectionModalStyles from "./BadgeSelectionModalStyles.module.css";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";

const BadgeSelectionModal = ({
  setModalRecognition,
  socket,
}: RecognitionBlockProps) => {
  const [selectedBadge, setSelectedBadge] =
    useState<AdminCreateBadgeProps | null>(null);
  const [selectBadgeStepOne, setSelectBadgeStepOne] = useState<boolean>(true);
  const [giveBadgeStepTwo, setGiveBadgeStepTwo] = useState<boolean>(false);
  const [stepOneErrorMessage, setStepOneErrorMessage] =
    useState<boolean>(false);

  const onCloseClickHandler = () => {
    if (setModalRecognition) {
      setModalRecognition(false);
    }
  };

  const onBadgeSelectClickHandler = (obj: AdminCreateBadgeProps) => {
    setSelectedBadge(obj);
    setStepOneErrorMessage(false);
  };

  const stepTwoOnClickHandler = () => {
    if (!selectedBadge) {
      return setStepOneErrorMessage(true);
    }
    setSelectBadgeStepOne(false);
    setGiveBadgeStepTwo(true);
  };

  return (
    <div className={BadgeSelectionModalStyles.badgeSelectionModal}>
      <div
        className={BadgeSelectionModalStyles.badgeSelectionPromptMainContainer}>
        {selectBadgeStepOne && (
          <StepOne
            selectedBadge={selectedBadge}
            onBadgeSelectClickHandler={onBadgeSelectClickHandler}
            stepTwoOnClickHandler={stepTwoOnClickHandler}
            onCloseClickHandler={onCloseClickHandler}
            stepOneErrorMessage={stepOneErrorMessage}
          />
        )}
        {giveBadgeStepTwo && (
          <StepTwo
            selectedBadge={selectedBadge}
            onBadgeSelectClickHandler={onBadgeSelectClickHandler}
            stepTwoOnClickHandler={stepTwoOnClickHandler}
            onCloseClickHandler={onCloseClickHandler}
            setSelectBadgeStepOne={setSelectBadgeStepOne}
            setGiveBadgeStepTwo={setGiveBadgeStepTwo}
            setModalRecognition={setModalRecognition}
            socket={socket}
          />
        )}
      </div>
    </div>
  );
};

export default BadgeSelectionModal;
