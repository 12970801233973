import axiosInstance from "./axiosInstance";
import axios from "axios";
import {CreateBadgePostType} from "../types/ComponentProps";

const createBadge = async (formData: CreateBadgePostType) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const data = await axiosInstance.post("/api/createBadge", formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message =
        error.response?.data?.message || "An unexpected error occurred.";
      throw new Error(message);
    } else {
      throw new Error("An unexpected error occurred.");
    }
  }
};

export default createBadge;
