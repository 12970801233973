import {createContext, useContext, useState, useEffect, ReactNode} from "react";
import {jwtDecode} from "jwt-decode";
import {io, Socket} from "socket.io-client";
import {
  TokenPayload,
  NotificationContextTypeProps,
} from "../types/ComponentProps";
import NotificationModal from "../components/notificationModal/NotificationModal";

const socketURL = process.env.REACT_APP_SOCKET_BASE_URL;

const NotificationContext = createContext<
  NotificationContextTypeProps | undefined
>(undefined);

export const NotificationProvider = ({children}: {children: ReactNode}) => {
  const token = localStorage.getItem("accessTokenGC");

  if (!token) {
    throw new Error("No access token found");
  }

  const userDetails: TokenPayload = jwtDecode(token);

  const [socketState, setSocketState] = useState<Socket | null>(null);
  const [notificationModal, setNotificationModal] = useState(false);
  const [selectedPostNotification, setSelectedPostNotification] =
    useState<any>(null);
  const [selectedNotificationId, setSelectedNotificationId] =
    useState<any>(null);

  // UseEffects
  useEffect(() => {
    const socket = io(socketURL);
    setSocketState(socket);
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socketState?.emit("newUser", userDetails);
  }, [socketState, userDetails]);

  return (
    <NotificationContext.Provider
      value={{
        userDetails,
        socketState,
        setNotificationModal,
        setSelectedNotificationId,
        setSelectedPostNotification,
      }}>
      {notificationModal ? (
        <NotificationModal
          notificationModal={notificationModal}
          setNotificationModal={setNotificationModal}
          selectedPostNotification={selectedPostNotification}
          setSelectedPostNotification={setSelectedPostNotification}
          selectedNotificationId={selectedNotificationId}
        />
      ) : null}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};
