import {HashTagModalProps} from "../../types/ComponentProps";
import HashTagModal from "./HashTagModal";
import HashTagButtonStyles from "./HashTagButtonStyles.module.css";

const HashTagButton = ({
  formPost,
  hashTagModal,
  setHashTagModal,
  setFormPost,
  selectedHashTag,
  setSelectedHashTag,
}: HashTagModalProps) => {
  const onClickHandler = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (setHashTagModal) {
      setHashTagModal(!hashTagModal);
    }
  };

  return (
    <div className={HashTagButtonStyles.hashTagButtonContainer}>
      {hashTagModal ? (
        <HashTagModal
          formPost={formPost}
          setFormPost={setFormPost}
          setHashTagModal={setHashTagModal}
          selectedHashTag={selectedHashTag}
          setSelectedHashTag={setSelectedHashTag}
        />
      ) : null}
      <button
        className={HashTagButtonStyles.hashTagButton}
        onClick={onClickHandler}>
        <i className="fa-solid fa-hashtag"></i> Hashtag
      </button>
    </div>
  );
};

export default HashTagButton;
