import {useInfiniteQuery} from "@tanstack/react-query";
import getLeaderBoardList from "../api/getLeaderBoardList";

const useGetLeaderBoard = () => {
  const {
    data: leaderBoardList,
    error: errorLeaderBoardList,
    isFetchingNextPage: isFetchingNextPageLeaderBoardList,
    isLoading: isLoadingLeaderBoardList,
    isError: isErrorLoadingLeaderBoardList,
    fetchNextPage: fetchNextPageLeaderBoardList,
    hasNextPage: hasNextPageLeaderBoardList,
    refetch: refetchLeaderBoardList,
  } = useInfiniteQuery({
    queryKey: ["getLeaderBoardList"],
    queryFn: ({pageParam = 1}) => getLeaderBoardList(pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage <= lastPage.totalPages
        ? lastPage.nextPage
        : undefined;
    },
    refetchOnWindowFocus: false,
  });

  return {
    leaderBoardList,
    errorLeaderBoardList,
    isFetchingNextPageLeaderBoardList,
    isLoadingLeaderBoardList,
    isErrorLoadingLeaderBoardList,
    fetchNextPageLeaderBoardList,
    hasNextPageLeaderBoardList,
    refetchLeaderBoardList,
  };
};

export default useGetLeaderBoard;
