import axiosInstance from "./axiosInstance";
import {CommentPostFooterProps} from "../types/ComponentProps";

const addComment = async (formData: CommentPostFooterProps, postId: number) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const data = await axiosInstance.post(
      `/api/createAPostComment/${postId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw new Error("Error creating user");
  }
};

export default addComment;
