import {useState, useEffect} from "react";
import Spinner from "../spinner/Spinner";
import {useMutation} from "@tanstack/react-query";
import {jwtDecode} from "jwt-decode";
import editUserDetail from "../../api/editUserDetail";
import Message from "../message/Message";
import useGetUserDetailsById from "../../hooks/useGetUserDetailsById";
import {TokenPayload} from "../../types/ComponentProps";
import EditProfileSectionStyles from "./EditProfileSectionStyles.module.css";
import CustomSelect from "../customSelect/CustomSelect";
import DatePickerComponent from "../datePicker/DatePickerComponent";
import {pronounOptions, provinceOptions} from "./dropdown";

const EditProfileSection = () => {
  const initialState = {
    preferredName: "",
    pronouns: "",
    bio: "",
    city: "",
    province: "",
    country: "",
    birthday: "",
  };

  const [form, setForm] = useState(initialState);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [messagePrompt, setMessagePrompt] = useState(false);
  const [exceedLimitParagraph, setExceedLimitParagraph] = useState(false);
  const userDetails = jwtDecode<TokenPayload>(
    JSON.stringify(localStorage.getItem("accessTokenGC"))
  );
  const {userDetailsById} = useGetUserDetailsById(Number(userDetails?.id));
  const [messagePromptName, setMessagePromptName] = useState(false);

  useEffect(() => {
    if (userDetailsById?.user) {
      setForm((prevForm) => ({
        ...prevForm,
        preferredName: userDetailsById.user.preferredName || "",
        pronouns: userDetailsById?.user?.user_profile?.pronouns || "",
        bio: userDetailsById?.user?.user_profile?.bio || "",
        city: userDetailsById?.user?.user_profile?.city || "",
        province: userDetailsById?.user?.user_profile?.province || "",
        country: userDetailsById?.user?.user_profile?.country || "",
        birthday: userDetailsById?.user?.user_profile?.birthday || "",
      }));
    }
  }, [userDetailsById]);

  useEffect(() => {
    if (messagePrompt) {
      const timer = setTimeout(() => {
        setMessagePrompt(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [messagePrompt]);

  const onChangeFormHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {value, name} = e.target;
    const MAX_BIO_LENGTH = 200;

    if (name === "preferredName") {
      const lettersOnly = /^[A-Za-z\s]*$/;
      if (!lettersOnly.test(value)) {
        return;
      }
    }

    if (name === "bio" && value.length > MAX_BIO_LENGTH) {
      setExceedLimitParagraph(true);
      return;
    } else if (
      name === "bio" &&
      exceedLimitParagraph &&
      value.length <= MAX_BIO_LENGTH
    ) {
      setExceedLimitParagraph(false);
    }
    setForm((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const editUserDetailMutation = useMutation({
    mutationFn: () => editUserDetail(Number(userDetails?.id), form),
    onSuccess: async () => {
      setUpdateLoading(false);
      setMessagePrompt(true);
    },
    onError: (error: Error) => {
      console.log(`Error updating user details`, error);
      setUpdateLoading(false);
    },
  });

  const onEditFormSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setExceedLimitParagraph(false);
    setUpdateLoading(true);

    if (form.preferredName === "") {
      setUpdateLoading(false);
      setMessagePromptName(true);
      return;
    }
    editUserDetailMutation.mutate();
  };

  useEffect(() => {
    if (messagePromptName && messagePrompt) {
      setMessagePromptName(false);
      return;
    }
  }, [messagePromptName, messagePrompt, setMessagePromptName]);

  const handleSelect =
    (field: "pronouns" | "province") =>
    (selectedOption: {value: string; label: string}) => {
      setForm((prevForm) => ({
        ...prevForm,
        [field]: selectedOption.value,
      }));
    };

  const closeMessageValidation = () => {
    setMessagePromptName?.(false);
  };

  return (
    <div
      className={`edit-profile-main-container ${EditProfileSectionStyles.mainContainerBlk}`}>
      <form onSubmit={onEditFormSubmitHandler}>
        <div className={EditProfileSectionStyles.flexBoxBlk}>
          <div>
            <label>Name</label>
            <input
              type="text"
              spellCheck="false"
              value={`${userDetailsById?.user?.firstName || ""} ${
                userDetailsById?.user?.lastName || ""
              }`}
              disabled
            />
          </div>
          <div>
            <label>Preferred Name</label>
            <input
              type="text"
              spellCheck="false"
              onChange={onChangeFormHandler}
              name="preferredName"
              value={form.preferredName}
              autoComplete="off"
              maxLength={30}
            />
          </div>
          <div>
            <label>Pronouns</label>
            <CustomSelect
              options={pronounOptions}
              onSelect={handleSelect("pronouns")}
              placeholder={
                userDetailsById?.user?.user_profile?.pronouns
                  ? userDetailsById?.user?.user_profile?.pronouns
                  : "Select a pronoun..."
              }
            />
          </div>
        </div>
        <div className={EditProfileSectionStyles.flexBoxBlk}>
          <div>
            <label>Email</label>
            <input
              type="text"
              spellCheck="false"
              value={userDetailsById?.user?.email || ""}
              disabled
            />
          </div>
          <div>
            <label>Mobile Number</label>
            <input
              type="text"
              spellCheck="false"
              value={`+63${
                userDetailsById?.user?.mobilePhone === null
                  ? ""
                  : userDetailsById?.user?.mobilePhone
              }`}
              disabled
            />
          </div>
          <div>
            <label>Birthday</label>
            <DatePickerComponent
              birthday={form.birthday}
              setFormInput={setForm}
              resetDate={false}
            />
          </div>
        </div>
        <div className={EditProfileSectionStyles.flexBoxBlk}>
          <div>
            <label>City</label>
            <input
              type="text"
              spellCheck="false"
              onChange={onChangeFormHandler}
              name="city"
              autoComplete="off"
              value={
                form.city === ""
                  ? "Auto-filled by the system; no user input required."
                  : ""
              }
              disabled
            />
          </div>
          <div>
            <label>Province</label>
            <CustomSelect
              options={provinceOptions}
              onSelect={handleSelect("province")}
              placeholder={
                userDetailsById?.user?.user_profile?.province
                  ? userDetailsById?.user?.user_profile?.province
                  : "Select Province"
              }
            />
          </div>
          <div>
            <label>Country</label>
            <input
              type="text"
              spellCheck="false"
              onChange={onChangeFormHandler}
              disabled
              name="country"
              autoComplete="off"
              value={
                form.country === ""
                  ? "Auto-filled by the system; no user input required."
                  : ""
              }
            />
          </div>
        </div>
        <div className={EditProfileSectionStyles.bioSectionBlk}>
          <label>Bio</label>
          <textarea
            spellCheck="false"
            onChange={onChangeFormHandler}
            name="bio"
            autoComplete="off"
            value={form.bio}></textarea>
          {exceedLimitParagraph ? (
            <p className={EditProfileSectionStyles.limitParagraph}>
              You have reached the maximum character limit.
            </p>
          ) : null}
        </div>

        {messagePrompt && (
          <div style={{marginTop: "20px", color: "#fff"}}>
            <Message variant="success">
              You have successfully updated your account.
            </Message>
          </div>
        )}

        {messagePromptName && (
          <div style={{marginTop: "20px", color: "#fff"}}>
            <Message variant="error">
              Preferred name is required.{" "}
              <i
                onClick={closeMessageValidation}
                className="fa-solid fa-xmark"></i>
            </Message>
          </div>
        )}

        <button
          type="submit"
          className={EditProfileSectionStyles.updateButtonBlk}>
          <div className={EditProfileSectionStyles.flexBoxBlkSection}>
            {updateLoading ? (
              <Spinner variant="small-spinner" alignSpin="center-spinner" />
            ) : (
              <>
                <p>Save</p>
                <i
                  className={`${EditProfileSectionStyles.updateButtonBlkIcon} fa-solid fa-arrow-right`}></i>
              </>
            )}
          </div>
        </button>
      </form>
    </div>
  );
};

export default EditProfileSection;
