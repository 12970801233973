import {Outlet, Navigate} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {jwtDecode} from "jwt-decode";
import {TokenPayload} from "../types/ComponentProps";

const AdminRoute = () => {
  const {accounts} = useMsal();
  const isAuthenticated = accounts.length > 0;
  const accessToken = localStorage.getItem("accessTokenGC");
  if (!isAuthenticated || !accessToken) {
    return <Navigate to="/login" />;
  }
  try {
    const userDetails: TokenPayload = jwtDecode<TokenPayload>(accessToken);
    if (userDetails?.isAdmin) {
      return <Outlet />;
    } else {
      return <Navigate to="/" />;
    }
  } catch (error) {
    console.error("Error decoding token:", error);
    return <Navigate to="/login" />;
  }
};

export default AdminRoute;
