import LeaderboardCard from "./LeaderboardCard";
import Spinner from "../spinner/Spinner";
import LeaderBoardStyles from "./LeaderBoardStyles.module.css";
import {
  LeaderBoardProps,
  LeaderBoardUserPointProps,
} from "../../types/ComponentProps";

const LeaderboardList = ({
  leaderBoardList,
  leaderBoardListLoading,
}: LeaderBoardProps) => {
  return (
    <div className={LeaderBoardStyles?.leaderboardListContainer}>
      {leaderBoardListLoading ? (
        <Spinner alignSpin="center-spinner" variant="small-spinner" />
      ) : (
        leaderBoardList
          ?.slice(0, 3)
          .map((data: LeaderBoardUserPointProps, index: number) => {
            return <LeaderboardCard key={index} data={data} />;
          })
      )}
    </div>
  );
};

export default LeaderboardList;
