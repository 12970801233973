import React from "react";
import MessageStyles from "./Message.module.css";

type MessageProps = {
  children: React.ReactNode;
  variant: "success" | "error";
};

const Message = ({children, variant}: MessageProps) => {
  return (
    <div
      className={`message-prompt-blk ${
        variant === "success" ? MessageStyles.success : MessageStyles.error
      }`}>
      {children}
    </div>
  );
};

export default Message;
