import React from "react";
import InputNumberStyles from "./InputNumberStyles.module.css";

type InputProps = {
  type?: string;
  value?: string;
  name?: string;
  label?: string;
  onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
};

const InputNumber = ({
  type,
  value,
  name,
  label,
  onChangeHandler,
  placeholder,
}: InputProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const invalidKeys = ["e", "E", "+", "-"];
    const maxLength = 6;
    if (invalidKeys.includes(event.key)) {
      event.preventDefault();
    }
    if (value && value.length >= maxLength && event.key !== "Backspace") {
      event.preventDefault();
    }
  };
  return (
    <>
      {label ? (
        <label className={InputNumberStyles.label} htmlFor={name}>
          {label}
        </label>
      ) : null}
      <div className={InputNumberStyles.labelInputMobilePhone}>
        <input
          className={InputNumberStyles.input}
          type={type}
          value={value}
          name={name}
          onChange={onChangeHandler}
          placeholder={placeholder}
          autoComplete="off"
          onKeyDown={handleKeyDown}
        />
      </div>
    </>
  );
};

export default InputNumber;
