import {useQuery} from "@tanstack/react-query";
import getBadgeById from "../api/getBadgeById";

const useGetBadgeById = (userId: number) => {
  const {
    data: getBadgeByIdData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["getBadgeById", userId],
    queryFn: () => getBadgeById(userId),
    enabled: userId > 0,
    refetchOnWindowFocus: false,
  });
  return {isLoading, getBadgeByIdData, isError, error};
};

export default useGetBadgeById;
