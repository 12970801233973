import {useQuery} from "@tanstack/react-query";
import getMultipleBadgesByIds from "../api/getMultipleBadgesByIds";

const useGetMultipleBadgesByIds = (badgeIds: number[]) => {
  const {
    data: multipleBadgesData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["getMultipleBadgesByIds", badgeIds],
    queryFn: () => getMultipleBadgesByIds(badgeIds),
    refetchOnWindowFocus: false,
    enabled: badgeIds.length > 0,
  });

  return {isLoading, multipleBadgesData, isError, error};
};

export default useGetMultipleBadgesByIds;
