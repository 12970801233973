import {RecipientModalProps} from "../../types/ComponentProps";
import RecipientModal from "./RecipientModal";
import RecipientButtonStyles from "./RecipientButtonStyles.module.css";

const RecipientButton = ({
  formPost,
  setFormPost,
  recipientModal,
  setRecipientModal,
  filteredUsers,
  setFilteredUsers,
}: RecipientModalProps) => {
  const onClickHandler = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (setRecipientModal) {
      setRecipientModal(!recipientModal);
    }
  };

  return (
    <div className={RecipientButtonStyles.recipientButtonContainer}>
      {recipientModal ? (
        <RecipientModal
          formPost={formPost}
          setFormPost={setFormPost}
          filteredUsers={filteredUsers}
          setRecipientModal={setRecipientModal}
          setFilteredUsers={setFilteredUsers}
        />
      ) : null}
      <button
        className={RecipientButtonStyles.recipientButton}
        onClick={onClickHandler}>
        <i className="fa-solid fa-at"></i> Recipient
      </button>
    </div>
  );
};

export default RecipientButton;
