import {useInfiniteQuery} from "@tanstack/react-query";
import getAllHashTag from "../api/getAllHashTag";

const useGetAllHashTag = () => {
  const {
    data: getAllDataHashTag,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["getAllHashTag"],
    queryFn: getAllHashTag,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage <= lastPage.totalPages
        ? lastPage.nextPage
        : undefined;
    },
  });

  return {
    isLoading,
    getAllDataHashTag,
    isError,
    error,
    refetch,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useGetAllHashTag;
