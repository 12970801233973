import axiosInstance from "./axiosInstance";
import {CreateUserProps} from "../types/ComponentProps";

const createUser = async (formData: CreateUserProps) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const data = await axiosInstance.post("/api/register", formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    throw new Error("Error creating user");
  }
};

export default createUser;
