import React from "react";
import InputStyles from "./InputStyles.module.css";

type InputProps = {
  type?: string;
  value?: string;
  name?: string;
  label?: string;
  onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
};

const Input = ({
  type,
  value,
  name,
  label,
  onChangeHandler,
  placeholder,
}: InputProps) => {
  return (
    <>
      {label ? (
        <label className={InputStyles.label} htmlFor={name}>
          {label}
        </label>
      ) : null}
      <input
        className={InputStyles.input}
        type={type}
        value={value}
        name={name}
        onChange={onChangeHandler}
        placeholder={placeholder}
        autoComplete="off"
        autoCorrect="off"
      />
    </>
  );
};

export default Input;
