import {useEffect} from "react";
import {useInView} from "react-intersection-observer";
import Message from "../message/Message";
import Spinner from "../spinner/Spinner";
import useGetAllBadges from "../../hooks/useGetAllBadges";
import {
  AdminCreateBadgeProps,
  SteOneProps,
  BadgeArrayProps,
} from "../../types/ComponentProps";
import BadgeSelectionImage from "../../assets/img/dashboard/badge-selection-image.png";
import BadgeSelectionModalStyles from "./BadgeSelectionModalStyles.module.css";

const StepOne = ({
  onCloseClickHandler,
  selectedBadge,
  onBadgeSelectClickHandler,
  stepTwoOnClickHandler,
  stepOneErrorMessage,
}: SteOneProps) => {
  const {
    getAllBadgesData,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetAllBadges();
  const {ref, inView} = useInView();

  const badgesArray =
    getAllBadgesData?.pages.flatMap((page: BadgeArrayProps) => page.badges) ||
    [];

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  return (
    <div className={BadgeSelectionModalStyles.stepOneContainer}>
      <div className={BadgeSelectionModalStyles.flexBoxBlk}>
        <div>
          <h1>Select Badge</h1>
        </div>
        <div>
          <span
            className={`fa-solid fa-xmark ${BadgeSelectionModalStyles.closeIconBadge}`}
            onClick={onCloseClickHandler}></span>
        </div>
      </div>
      <hr className={BadgeSelectionModalStyles.horizontalLine} />
      <img
        src={BadgeSelectionImage}
        className={BadgeSelectionModalStyles.headerImage}
        alt="Badge Selection"
      />
      <div className={BadgeSelectionModalStyles.scrollContainer}>
        {isLoading ? (
          <Spinner variant="medium-spinner" alignSpin="center-spinner" />
        ) : badgesArray?.length === 0 ? (
          <p style={{color: "#FECF0C"}}>No Badge available</p>
        ) : (
          badgesArray.map((data: AdminCreateBadgeProps, index: number) => {
            const isSelected = selectedBadge?.badgeName === data.badgeName;
            return (
              <div
                className={`${BadgeSelectionModalStyles.badgeListFlexBox} ${
                  isSelected ? BadgeSelectionModalStyles.selectedBadge : ""
                }`}
                onClick={() => onBadgeSelectClickHandler(data)}
                key={index}>
                <div>
                  <div
                    className={`${
                      BadgeSelectionModalStyles.customRadionButton
                    } ${
                      isSelected
                        ? BadgeSelectionModalStyles.selectedBadgeRadio
                        : ""
                    }`}></div>
                </div>
                <div>
                  <div
                    className={BadgeSelectionModalStyles.titleSectionFlexBox}>
                    <h1>{data?.badgeName}</h1>
                    <span>+{data?.points}</span>
                  </div>
                  <p>{data?.message}</p>
                </div>
              </div>
            );
          })
        )}
        <div ref={ref} style={{height: "1px"}}></div>
        {isFetchingNextPage && (
          <div style={{display: "block", padding: "20px 0px"}}>
            <Spinner variant="medium-spinner" alignSpin="center-spinner" />
          </div>
        )}
      </div>

      {stepOneErrorMessage && (
        <div style={{marginTop: "10px"}}>
          <Message variant="error">Select a badge to proceed</Message>
        </div>
      )}
      <div className={BadgeSelectionModalStyles.buttonNextBlk}>
        <button onClick={stepTwoOnClickHandler}>Next</button>
      </div>
    </div>
  );
};

export default StepOne;
