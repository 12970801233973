import {useInfiniteQuery} from "@tanstack/react-query";
import getAllPostsById from "../api/getAllPostsById";

const useGetAllPostsById = (userId: number) => {
  const {
    data: getAllPostsByIdData,
    error,
    isFetchingNextPage,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    refetch: refetchGetAllPostsByIdData,
  } = useInfiniteQuery({
    queryKey: ["getAllPostsById", userId],
    queryFn: ({pageParam = 1}) => getAllPostsById(userId, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage <= lastPage.totalPages
        ? lastPage.nextPage
        : undefined;
    },
    refetchOnWindowFocus: false,
  });

  return {
    error,
    isFetchingNextPage,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    getAllPostsByIdData,
    refetchGetAllPostsByIdData,
  };
};

export default useGetAllPostsById;
