import useGetPostCommentsById from "../../hooks/useGetPostCommentsById";
import {CommentButtonProps} from "../../types/ComponentProps";
import CommentButtonStyles from "./CommentButtonStyles.module.css";

const CommentButton = ({post}: CommentButtonProps) => {
  const {getPostComments} = useGetPostCommentsById(post?.postId ?? 0);
  return (
    <button className={CommentButtonStyles.commentButton}>
      <i className="fa-regular fa-message"></i>
      <span>
        {getPostComments?.pages[0]?.pagination?.totalItems === 1
          ? `${getPostComments?.pages[0]?.pagination?.totalItems} Comment`
          : getPostComments?.pages[0]?.pagination?.totalItems > 1
          ? `${getPostComments?.pages[0]?.pagination?.totalItems} Comments`
          : `Comment`}
      </span>
    </button>
  );
};

export default CommentButton;
