import {useQuery} from "@tanstack/react-query";
import getTeamList from "../api/getTeamList";

const useGetTeamList = () => {
  const {
    data: getTeamListData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["getTeamList"],
    queryFn: getTeamList,
  });

  return {isLoading, getTeamListData, isError, error};
};

export default useGetTeamList;
