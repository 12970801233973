import {useEffect, useState} from "react";
import NotificationList from "./NotificationList";
import {
  NavigationProps,
  NotificationSenderProps,
} from "../../types/ComponentProps";
import NotificationStyles from "./Notification.module.css";
import useGetAllNotification from "../../hooks/useGetAllNotification";

const Notification = ({
  socket,
  notificationToggle,
  setNotificationToogle,
  setNotificationModal,
  setSelectedPostNotification,
  setSelectedNotificationId,
}: NavigationProps) => {
  const {getNotificationByIdData, refetch, isLoading} = useGetAllNotification();
  const [notification, setNotifications] = useState<NotificationSenderProps>(
    []
  );

  const onClickHandler = () => {
    if (setNotificationToogle) {
      setNotificationToogle(!notificationToggle);
    }
  };

  useEffect(() => {
    if (notification.length > 0) {
      refetch();
    }
  }, [notification, refetch]);

  useEffect(() => {
    if (socket) {
      socket.on("getNotification", (data) => {
        setNotifications((previous) => [...previous, data]);
      });

      socket.on("getNotificationComment", (data) => {
        setNotifications((previous) => [...previous, data]);
      });

      socket.on("getNotificationMention", (data) => {
        setNotifications((previous) => [...previous, data]);
      });

      socket.on("getNotificationBadgeMention", (data) => {
        setNotifications((previous) => [...previous, data]);
      });

      return () => {
        socket.off("getNotification");
        socket.off("getNotificationComment");
        socket.off("getNotificationMention");
      };
    }
  }, [socket]);

  return (
    <div className={NotificationStyles.notificationMainContainer}>
      {isLoading ? null : getNotificationByIdData?.notifications.length ===
        0 ? null : (
        <div className={NotificationStyles.notificationSpanCounter}>
          <span>
            {getNotificationByIdData?.notifications.length === 99
              ? `${getNotificationByIdData?.notifications.length}+`
              : getNotificationByIdData?.notifications.length}
          </span>
        </div>
      )}
      {notificationToggle ? (
        <NotificationList
          setToggle={setNotificationToogle}
          setNotificationModal={setNotificationModal}
          setSelectedPostNotification={setSelectedPostNotification}
          setSelectedNotificationId={setSelectedNotificationId}
        />
      ) : null}
      <div className={NotificationStyles.notificationContainer}>
        <i className="fa-solid fa-bell" onClick={onClickHandler}></i>
      </div>
    </div>
  );
};

export default Notification;
