import React, {useState, useRef, useEffect} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import createBadge from "../../api/createBadge";
import Input from "../input/Input";
import InputNumber from "../inputNumber/InputNumber";
import CustomSelect from "../customSelect/CustomSelect";
import CustomSelectHashTag from "../customSelectHashTag/CustomSelectHashTag";
import {badgeFrequency} from "./dropdown";
import {
  BadgeModalProps,
  HashTagPage,
  CreateBadgePostType,
  CustomSelectHashTagType,
  CustomSelectOption,
  HashTagListProps,
} from "../../types/ComponentProps";
import Spinner from "../spinner/Spinner";
import BadgeModalStyles from "./BadgeModalStyles.module.css";
import DefaultBadge from "../../assets/img/dashboard/default-badge.png";
import useGetAllHashTag from "../../hooks/useGetAllHashTag";
import Message from "../message/Message";

const BadgeModal = ({setBadgeModal}: BadgeModalProps) => {
  const queryClient = useQueryClient();
  const initialState: CreateBadgePostType = {
    badgeName: "",
    badgeImage: null,
    points: null,
    frequency: null,
    hashTagIds: [],
    message: "",
  };
  const {getAllDataHashTag, hasNextPage, fetchNextPage} = useGetAllHashTag();
  const [createBadgeForm, setCreateBadgeForm] =
    useState<CreateBadgePostType>(initialState);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [createBadgeLoading, setCreateBadgeLoading] = useState<boolean>(false);
  const [formValidation, setFormValidation] = useState<boolean>(false);

  const createBadgeMutation = useMutation({
    mutationFn: () => createBadge(createBadgeForm),
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({queryKey: ["getAllBadges"]});
        if (setBadgeModal) {
          setBadgeModal(false);
        }

        setCreateBadgeLoading(false);
      } catch (error) {
        console.error("Error invalidating queries:", error);
      }
    },
    onError: (error: Error) => {
      console.error("Error create database:", error);
    },
  });

  useEffect(() => {
    if (createBadgeForm.badgeImage) {
      const imageUrl = URL.createObjectURL(createBadgeForm.badgeImage);
      setImagePreview(imageUrl);
      return () => URL.revokeObjectURL(imageUrl);
    }
    setImagePreview(null);
  }, [createBadgeForm.badgeImage]);

  const closeHandler = () => {
    if (setBadgeModal) {
      setBadgeModal(false);
    }
  };

  const onCreateBadgeClickHandler = () => {
    const {badgeName, badgeImage, points, frequency, hashTagIds, message} =
      createBadgeForm || {};

    const isFormIncomplete =
      [badgeName, badgeImage, points, frequency, message].some(
        (field) => !field
      ) ||
      frequency === "Blank" ||
      !hashTagIds ||
      (Array.isArray(hashTagIds) && hashTagIds.length === 0);

    if (isFormIncomplete) {
      return setFormValidation(true);
    }

    setFormValidation(false);
    setCreateBadgeLoading(true);
    createBadgeMutation.mutate();
  };

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = e.target;
    setCreateBadgeForm((previous) => ({
      ...previous,
      [name]: value,
    }));
    setFormValidation(false);
  };

  const imageUploadHandler = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (validImageTypes.includes(file.type)) {
        setCreateBadgeForm((previous) => ({
          ...previous,
          badgeImage: file,
        }));
      }
    }
    setFormValidation(false);
  };

  const closeImagePreviewClickHandler = () => {
    setImagePreview(null);
    setCreateBadgeForm((previous) => ({
      ...previous,
      badgeImage: null,
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSelect =
    (field: "frequency") => (selectedOption: CustomSelectOption) => {
      setCreateBadgeForm((prevForm) => ({
        ...prevForm,
        [field]: selectedOption.value,
      }));
      setFormValidation(false);
    };

  const handleSelectHashTag =
    (field: "hashTagIds") => (selectedOption: CustomSelectHashTagType) => {
      setCreateBadgeForm((prevForm: CreateBadgePostType) => ({
        ...prevForm,
        [field]: [
          ...(prevForm?.hashTagIds || []),
          {
            hashTagId: selectedOption?.hashTagId,
            hashTagName: selectedOption?.hashTagName,
          },
        ],
      }));
      setFormValidation(false);
    };

  const removeListHashTag = (hashTagId: number) => {
    setCreateBadgeForm((prevForm: CreateBadgePostType) => ({
      ...prevForm,
      hashTagIds:
        prevForm.hashTagIds?.filter(
          (tag: HashTagListProps) => tag.hashTagId !== hashTagId
        ) || [],
    }));
  };

  const filteredHashTags =
    getAllDataHashTag?.pages
      .flatMap((page: HashTagPage) => page.hashTags)
      .filter(
        (hashTag: CustomSelectHashTagType) =>
          !createBadgeForm?.hashTagIds?.some(
            (selectedTag: HashTagListProps) =>
              selectedTag?.hashTagId === hashTag.hashTagId
          )
      ) || [];

  return (
    <div className={BadgeModalStyles.createBadgeModal}>
      <div className={BadgeModalStyles.createBadgePromptMainContainer}>
        <div
          className={BadgeModalStyles.scrollContainer}
          style={
            !createBadgeForm?.hashTagIds?.length && !formValidation
              ? {overflow: "hidden", paddingRight: "0px"}
              : {overflowY: "scroll", paddingRight: "10px"}
          }>
          <div className={BadgeModalStyles.flexBoxBlk}>
            <div>
              <h1>Create new Badge</h1>
            </div>
            <div>
              <span
                className={`fa-solid fa-xmark ${BadgeModalStyles.closeIconCreateBadge}`}
                onClick={closeHandler}></span>
            </div>
          </div>
          <hr className={BadgeModalStyles.horizontalLine} />
          <div className={BadgeModalStyles.basicSettingsFieldSection}>
            <div className={BadgeModalStyles.basicSettingsFlexBoxBlk}>
              <div>
                <Input
                  type="text"
                  value={createBadgeForm.badgeName}
                  name="badgeName"
                  onChangeHandler={onChangeHandler}
                  label="Badge Name"
                />
              </div>
              <div>
                <div className={BadgeModalStyles.imageUploadSection}>
                  <label>Badge Icon</label>
                  <div className={BadgeModalStyles.uploadSectionFlexBox}>
                    <div>
                      {!imagePreview ? (
                        <img
                          src={DefaultBadge}
                          alt="Preview"
                          className={BadgeModalStyles.badgeImageContainer}
                        />
                      ) : null}
                      {imagePreview && (
                        <>
                          {imagePreview && (
                            <div
                              className={BadgeModalStyles.closeIconBlock}
                              onClick={closeImagePreviewClickHandler}>
                              <i className="fa-solid fa-xmark"></i>
                            </div>
                          )}
                          <img
                            src={imagePreview}
                            alt="Preview"
                            className={BadgeModalStyles.badgeImageContainer}
                          />
                        </>
                      )}
                    </div>
                    <div onClick={imageUploadHandler}>
                      {imagePreview ? (
                        <p className={BadgeModalStyles.uploadedFileName}>
                          {createBadgeForm?.badgeImage?.name}
                        </p>
                      ) : (
                        <>
                          <p className={BadgeModalStyles.uploadBlurb}>
                            Upload new badge
                          </p>
                          <p className={BadgeModalStyles.fileFormatBlurb}>
                            Formats JPEG, JPG, PNG, and GIF
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    name="badgeImage"
                    style={{display: "none"}}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>

            <div className={BadgeModalStyles.basicSettingsFlexBoxBlk}>
              <div>
                <InputNumber
                  type="number"
                  value={
                    createBadgeForm.points !== null
                      ? String(createBadgeForm.points)
                      : ""
                  }
                  name="points"
                  onChangeHandler={onChangeHandler}
                  label="Points"
                />
              </div>
              <div>
                <label>Frequency</label>
                <CustomSelect
                  options={badgeFrequency}
                  onSelect={handleSelect("frequency")}
                  placeholder={badgeFrequency[0].label}
                />
              </div>
            </div>

            <div className={BadgeModalStyles.basicSettingsFlexBoxBlk}>
              <div>
                <label>Hash Tags</label>
                <CustomSelectHashTag
                  options={filteredHashTags}
                  onSelect={handleSelectHashTag("hashTagIds")}
                  placeholder="Select HashTags"
                  hasNextPage={hasNextPage}
                  fetchNextPage={fetchNextPage}
                />
              </div>
            </div>
            {createBadgeForm?.hashTagIds?.length === 0 ? null : (
              <div className={BadgeModalStyles.hashTagNameFlex}>
                {createBadgeForm?.hashTagIds?.map(
                  (data: HashTagListProps, index: number) => {
                    return (
                      <h1 key={index} className={BadgeModalStyles.hashTagName}>
                        <p>#{data?.hashTagName} </p>
                        <p
                          onClick={() =>
                            removeListHashTag(Number(data?.hashTagId))
                          }>
                          x
                        </p>
                      </h1>
                    );
                  }
                )}
              </div>
            )}
            <div style={{marginTop: "20px"}}>
              <Input
                type="text"
                value={createBadgeForm.message}
                name="message"
                onChangeHandler={onChangeHandler}
                label="Message"
              />
            </div>
            {formValidation ? (
              <div style={{margin: "10px 0px"}}>
                <Message variant="error">
                  All fields must be completed to proceed
                </Message>
              </div>
            ) : null}
            <button
              className={BadgeModalStyles.buttonCreateBadge}
              onClick={onCreateBadgeClickHandler}>
              {createBadgeLoading ? (
                <Spinner variant="small-spinner" alignSpin="center-spinner" />
              ) : (
                <>
                  Create badge<i className="fa-solid fa-arrow-right"></i>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BadgeModal;
