import {useEffect} from "react";
import {useInView} from "react-intersection-observer";
import useGetAllHashTag from "../../hooks/useGetAllHashTag";
import {
  HashTagListProps,
  SelectedHashTagField,
  HashTagPropsCardList,
  HashTagModalProps,
  HashTagPage,
} from "../../types/ComponentProps";
import Spinner from "../spinner/Spinner";
import HashTagButtonStyles from "./HashTagButtonStyles.module.css";

const HashTagModal = ({
  setHashTagModal,
  selectedHashTag,
  setSelectedHashTag,
}: HashTagModalProps) => {
  const {ref, inView} = useInView();
  const {
    getAllDataHashTag,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetAllHashTag();

  const selectedHashTagIds = new Set(
    selectedHashTag?.map((tag: HashTagPropsCardList) => tag.hashTagId)
  );

  const filteredHashTags =
    getAllDataHashTag?.pages
      .flatMap((page: HashTagPage) => page.hashTags)
      .filter(
        (hashTag: HashTagListProps) =>
          !selectedHashTagIds.has(hashTag.hashTagId)
      ) || [];

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const handleSelectHashTag = (hashTags: HashTagPropsCardList) => {
    if (setSelectedHashTag && setHashTagModal) {
      setSelectedHashTag((previous: SelectedHashTagField) => [
        ...previous,
        hashTags,
      ]);
      setHashTagModal(false);
    }
  };

  const onMouseLeaveHandler = () => {
    if (setHashTagModal) {
      setHashTagModal(false);
    }
  };

  return (
    <div
      className={HashTagButtonStyles.modalContainer}
      style={filteredHashTags.length > 3 ? {overflowY: "scroll"} : {}}
      onMouseLeave={onMouseLeaveHandler}>
      {isLoading ? (
        <Spinner variant="medium-spinner" alignSpin="center-spinner" />
      ) : filteredHashTags.length === 0 ? (
        <p style={{textAlign: "center"}}>No hashtags available</p>
      ) : (
        filteredHashTags.map((data: HashTagListProps, index: number) => {
          const {hashTagId, hashTagName} = data;
          return (
            <div
              className={HashTagButtonStyles.hashTagList}
              key={index}
              onClick={() =>
                handleSelectHashTag({
                  hashTagId,
                  hashTagName,
                })
              }>
              <h3>#{data?.hashTagName}</h3>
            </div>
          );
        })
      )}
      <div ref={ref} style={{height: "10px"}}></div>
      {isFetchingNextPage && (
        <div style={{display: "block", padding: "10px 0"}}>
          <Spinner variant="medium-spinner" alignSpin="center-spinner" />
        </div>
      )}
    </div>
  );
};

export default HashTagModal;
