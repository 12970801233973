import axiosInstance from "./axiosInstance";
import {EditProfileProps} from "../types/ComponentProps";

const editUserDetail = async (
  userId: number,
  userDetails: EditProfileProps
) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const response = await axiosInstance.patch(
      `/api/user/${userId}`,
      {
        preferredName: userDetails?.preferredName,
        pronouns: userDetails?.pronouns,
        bio: userDetails?.bio,
        city: userDetails?.city,
        province: userDetails?.province,
        country: userDetails?.country,
        birthday: userDetails?.birthday,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Error creating user");
  }
};

export default editUserDetail;
