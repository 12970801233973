import React, {useState, useRef, useEffect} from "react";
import {useMutation} from "@tanstack/react-query";
import Message from "../../components/message/Message";
import createUser from "../../api/createUser";
import Spinner from "../../components/spinner/Spinner";
import Input from "../../components/input/Input";
import PhoneInput from "../../components/phoneInput/PhoneInput";
import WithNavTemplate from "../../components/template/WithNavTemplate";
import {CreateUserProps} from "../../types/ComponentProps";
import DatePickerComponent from "../../components/datePicker/DatePickerComponent";

const formInitialState: CreateUserProps = {
  email: "",
  firstName: "",
  lastName: "",
  preferredName: "",
  mobilePhone: "",
  jobTitle: "",
  birthday: "",
  profileImage: null,
};

const AdminCreateUser = () => {
  const [formInput, setFormInput] = useState<CreateUserProps>(formInitialState);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resetDate, setResetDate] = useState(false);
  const [messagePrompt, setMessagePrompt] = useState(false);
  const [errorPrompt, setErrorPrompt] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  useEffect(() => {
    if (formInput.profileImage) {
      const imageUrl = URL.createObjectURL(formInput.profileImage);
      setImagePreview(imageUrl);
      return () => URL.revokeObjectURL(imageUrl);
    }
    setImagePreview(null);
  }, [formInput.profileImage]);

  useEffect(() => {
    if (errorPrompt) {
      return setEmailError(false);
    }

    if (messagePrompt) {
      const timer = setTimeout(() => {
        setMessagePrompt(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [messagePrompt, errorPrompt]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone: string) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const createUserMutation = useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      setMessagePrompt(true);
      setIsLoading(false);
      setFormInput(formInitialState);
      setResetDate(true);
      setImagePreview(null);
      setEmailError(false);
      setPhoneError(false);
      setErrorPrompt(false);
    },
    onError: (error: Error) => {
      setIsLoading(false);
      console.error("Error creating user:", error);
    },
  });

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isEmailValid = validateEmail(formInput.email);
    const isPhoneValid = formInput.mobilePhone
      ? validatePhone(formInput.mobilePhone)
      : true;

    if (!isEmailValid) {
      setEmailError(true);
      return;
    }

    if (!isPhoneValid) {
      setPhoneError(true);
      return;
    }

    if (
      !formInput?.email ||
      !formInput?.firstName ||
      !formInput?.lastName ||
      !formInput?.preferredName ||
      !formInput?.jobTitle ||
      !formInput?.profileImage
    ) {
      setErrorPrompt(true);
      return;
    }

    setIsLoading(true);
    setErrorPrompt(false);
    setEmailError(false);
    setPhoneError(false);
    createUserMutation.mutate(formInput);
  };

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = e.target;
    setFormInput((previous) => ({
      ...previous,
      [name]: value,
    }));
    setErrorPrompt(false);
    if (name === "email") {
      setEmailError(false);
    }
    if (name === "mobilePhone") {
      setPhoneError(false);
    }
  };

  const imageUploadHandler = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (validImageTypes.includes(file.type)) {
        setFormInput((previous) => ({
          ...previous,
          profileImage: file,
        }));
      }
    }
  };

  const closeImagePreviewClickHandler = () => {
    setImagePreview(null);
    setFormInput((previous) => ({
      ...previous,
      profileImage: null,
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <WithNavTemplate>
      <div className="admin-create-user-page-container">
        <div className="main-container-blk">
          <div className="page-title-blk">
            <h1>Create User</h1>
          </div>
          <form onSubmit={onSubmitHandler}>
            <div className="form-container-main">
              <div>
                {imagePreview && (
                  <div
                    className="close-image-preview-icon-blk-create-user"
                    onClick={closeImagePreviewClickHandler}>
                    <i className="fa-solid fa-xmark"></i>
                  </div>
                )}
                <div
                  className="image-upload-section-blk"
                  onClick={imageUploadHandler}>
                  {imagePreview ? (
                    <img
                      src={imagePreview}
                      alt="Preview"
                      className="create-user-image-preview-blk"
                    />
                  ) : (
                    <p className="select-photo-paragraph">
                      <i className="fa-solid fa-upload"></i> Click here to
                      Upload profile image
                    </p>
                  )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    name="profileImage"
                    style={{display: "none"}}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              <div>
                <div className="flex-box-blk">
                  <div>
                    <Input
                      type="text"
                      value={formInput.email}
                      name="email"
                      onChangeHandler={onChangeHandler}
                      label="Email"
                      placeholder="Email"
                    />
                  </div>
                  <div>
                    <PhoneInput
                      type="text"
                      value={formInput.mobilePhone}
                      name="mobilePhone"
                      onChangeHandler={onChangeHandler}
                      label="Mobile Phone"
                      placeholder="Mobile Phone"
                    />
                  </div>
                </div>
                <div className="flex-box-blk">
                  <div>
                    <Input
                      type="text"
                      value={formInput.firstName}
                      name="firstName"
                      onChangeHandler={onChangeHandler}
                      label="First Name"
                      placeholder="First Name"
                    />
                  </div>
                  <div>
                    <Input
                      type="text"
                      value={formInput.lastName}
                      name="lastName"
                      onChangeHandler={onChangeHandler}
                      label="Last Name"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div className="flex-box-blk">
                  <div>
                    <Input
                      type="text"
                      value={formInput.preferredName}
                      name="preferredName"
                      onChangeHandler={onChangeHandler}
                      label="Preferred Name"
                      placeholder="Preferred Name"
                    />
                  </div>
                  <div>
                    <Input
                      type="text"
                      value={formInput.jobTitle}
                      name="jobTitle"
                      onChangeHandler={onChangeHandler}
                      label="Job Title"
                      placeholder="Job Title"
                    />
                  </div>
                </div>
                <div className="flex-box-blk">
                  <div>
                    <label className="class-label-blk">Birthday</label>
                    <DatePickerComponent
                      setFormInput={setFormInput}
                      resetDate={resetDate}
                    />
                  </div>
                </div>

                {messagePrompt && (
                  <div
                    style={{
                      display: "block",
                      marginBottom: "20px",
                      color: "#fff",
                    }}>
                    <Message variant="success">
                      You have successfully created an account.
                    </Message>
                  </div>
                )}

                {emailError ? (
                  <div style={{marginTop: "5px", marginBottom: "10px"}}>
                    <Message variant="error">Invalid email address</Message>
                  </div>
                ) : phoneError ? (
                  <div style={{marginTop: "5px", marginBottom: "10px"}}>
                    <Message variant="error">
                      The phone number must consist of exactly 10 digits and
                      contain only numerical characters.
                    </Message>
                  </div>
                ) : errorPrompt ? (
                  <div style={{marginTop: "5px", marginBottom: "10px"}}>
                    <Message variant="error">All fields are required.</Message>
                  </div>
                ) : null}

                <button className="create-user-button" type="submit">
                  {isLoading ? (
                    <Spinner
                      variant="small-spinner"
                      alignSpin="center-spinner"
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </WithNavTemplate>
  );
};

export default AdminCreateUser;
