import {useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";
import {
  RecipientListProps,
  ExtendedPostBlockProps,
  TokenPayload,
  CreatePostType,
  CreateUserProps,
} from "../../types/ComponentProps";
import useGetAllUsers from "../../hooks/useGetAllUsers";
import RecipientAvatar from "./RecipientAvatar";
import RecipientButtonStyles from "./RecipientButtonStyles.module.css";
import Spinner from "../spinner/Spinner";

const RecipientModal = ({
  setFormPost,
  filteredUsers,
  setFilteredUsers,
  setRecipientModal,
}: ExtendedPostBlockProps) => {
  const {getAllUsersData, isLoading} = useGetAllUsers();
  const [searchInput, setSearchInput] = useState<string>("");

  useEffect(() => {
    if (setFormPost && filteredUsers) {
      setFormPost((previous: CreatePostType) => ({
        ...previous,
        recipients: filteredUsers as CreateUserProps[],
      }));
    }
  }, [filteredUsers, setFormPost]);

  const onSelectClickHandler = (user: RecipientListProps) => {
    if (setFilteredUsers) {
      setFilteredUsers((previous) => {
        if (
          previous.some((selectedUser) => selectedUser.email === user.email)
        ) {
          return previous.filter(
            (selectedUser) => selectedUser.email !== user.email
          );
        } else {
          return [...previous, user];
        }
      });
    }
  };

  const getFilteredUsers = () => {
    if (!getAllUsersData?.users) return [];
    const token = localStorage.getItem("accessTokenGC");
    let email = "";
    if (token) {
      const decodedToken = jwtDecode<TokenPayload>(token);
      email = decodedToken.email;
    }
    return getAllUsersData?.users
      .filter((user: RecipientListProps) => user.email !== email)
      .filter(
        (user: RecipientListProps) =>
          !filteredUsers?.some(
            (filteredUser) => filteredUser.email === user.email
          )
      );
  };

  const searchUsers = (users: RecipientListProps[]) => {
    return users.filter(
      (user) =>
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        user.email.toLowerCase().includes(searchInput.toLowerCase())
    );
  };

  const allUsers = getFilteredUsers();
  const displayedUsers = searchUsers(allUsers).sort((a, b) =>
    `${b.firstName} ${b.lastName}`.localeCompare(`${a.firstName} ${a.lastName}`)
  );

  const onMouseLeaveHandler = () => {
    if (setRecipientModal) {
      setRecipientModal(false);
    }
  };

  return (
    <div
      className={RecipientButtonStyles.modalContainer}
      onMouseLeave={onMouseLeaveHandler}>
      <div className={RecipientButtonStyles.mainContainer}>
        {isLoading ? (
          <Spinner variant="medium-spinner" alignSpin="center-spinner" />
        ) : (
          <>
            <div className={RecipientButtonStyles.searchInputContainer}>
              <input
                className={RecipientButtonStyles.searchInputFilter}
                placeholder="Search User...."
                spellCheck="false"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
            {displayedUsers.length === 0 ? (
              <p className={RecipientButtonStyles.paragraphSelectedBlk}>
                No users found
              </p>
            ) : (
              displayedUsers.map((user: RecipientListProps, index: number) => (
                <div
                  key={index}
                  className={RecipientButtonStyles.listBlockContainer}
                  onClick={() => onSelectClickHandler(user)}>
                  <div>
                    <RecipientAvatar userId={user?.id} />
                  </div>
                  <div>
                    <h1>{`${user.firstName} ${user.lastName}`}</h1>
                    <p>{user.email.toLowerCase()}</p>
                  </div>
                </div>
              ))
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RecipientModal;
