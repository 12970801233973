import {useInfiniteQuery} from "@tanstack/react-query";
import getLikedPostsByUserId from "../api/getLikedPostsByUserId";

const useGetLikedPostsByUserId = (userId: number) => {
  const {
    data: userLikedPosts,
    error,
    isFetchingNextPage,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    refetch: refetchUserLikedPosts,
  } = useInfiniteQuery({
    queryKey: ["userLikedPosts", userId],
    queryFn: ({pageParam = 1}) => getLikedPostsByUserId(userId, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage <= lastPage.totalPages
        ? lastPage.nextPage
        : undefined;
    },
    refetchOnWindowFocus: false,
  });

  return {
    error,
    isFetchingNextPage,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    userLikedPosts,
    refetchUserLikedPosts,
  };
};

export default useGetLikedPostsByUserId;
