import React from "react";
import BlockHeader from "./BlockHeader";
import BlockStyles from "./BlockStyles.module.css";

type BlockProps = {
  title: string;
  navigation?: "dots" | "viewall" | null;
  linkURL?: string;
  children: React.ReactNode;
};

const Block = ({title, navigation, linkURL, children}: BlockProps) => {
  return (
    <div className={BlockStyles.blockContainer}>
      <div className={BlockStyles.flexBox}>
        <h3>{title}</h3>
        <BlockHeader navigation={navigation} linkURL={linkURL} />
      </div>
      {children}
    </div>
  );
};

export default Block;
