import axiosInstance from "./axiosInstance";

const getAllPostLikes = async (postId: number) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const {data} = await axiosInstance.get(`/api/getAllPostLikes/${postId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data?.showAllLikes;
  } catch (error) {
    throw new Error("Error creating user");
  }
};

export default getAllPostLikes;
