import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import {useNotification} from "../../context/NotificationContext";
import {TokenPayload} from "../../types/ComponentProps";
import WithNavTemplate from "../../components/template/WithNavTemplate";
import EditProfileSection from "../../components/editProfile/EditProfileSection";
import Navigation from "../../components/navigation/Navigation";
import Spinner from "../../components/spinner/Spinner";

const EditProfile = () => {
  const navigate = useNavigate();
  const {
    socketState,
    setNotificationModal,
    setSelectedPostNotification,
    setSelectedNotificationId,
  } = useNotification();
  const userDetails = jwtDecode<TokenPayload>(
    JSON.stringify(localStorage.getItem("accessTokenGC"))
  );

  useEffect(() => {
    if (userDetails?.isAdmin) {
      return navigate("/profile");
    }
  }, [navigate, userDetails?.isAdmin]);

  const navigationOnClick = () => {
    navigate("/profile");
  };

  if (!socketState) {
    return (
      <>
        <Navigation />
        <div className="inside-page-container-blk">
          <div
            className="main-container-blk"
            style={{
              position: "relative",
              height: "100vh",
              paddingTop: "200px",
            }}>
            <Spinner alignSpin="center-spinner" variant="large-spinner" />
          </div>
        </div>
      </>
    );
  }

  return (
    <WithNavTemplate
      socket={!socketState ? null : socketState}
      setNotificationModal={setNotificationModal}
      setSelectedPostNotification={setSelectedPostNotification}
      setSelectedNotificationId={setSelectedNotificationId}>
      <div className="inside-page-container-blk">
        <div className="edit-main-container">
          <div className="main-container-blk leaderboard-main-section">
            <div className="page-title-blk">
              <i
                onClick={navigationOnClick}
                className="fa-solid fa-arrow-left arrow-back-page"></i>
              <h1>Edit Profile</h1>
            </div>
            <EditProfileSection />
          </div>
        </div>
      </div>
    </WithNavTemplate>
  );
};

export default EditProfile;
