import {useQuery} from "@tanstack/react-query";
import getUsers from "../api/getUsers";

const useGetAllUsers = () => {
  const {
    data: getAllUsersData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["getAllUsers"],
    queryFn: getUsers,
    refetchOnWindowFocus: false,
  });
  return {isLoading, getAllUsersData, isError, error};
};

export default useGetAllUsers;
