import useGetProfilePicture from "../../hooks/useGetProfilePicture";
import TeamBlockStyles from "./TeamBlockStyles.module.css";
import DefaultImage from "../../assets/img/default-profile-photo.png";
import Spinner from "../spinner/Spinner";
import {AvatarLikeProps} from "../../types/ComponentProps";

const TeamBlockAvatar = ({userId}: AvatarLikeProps) => {
  const {isLoading, isFetching, isError, getProfileImage} =
    useGetProfilePicture(userId);

  if (isLoading || isFetching) {
    return (
      <Spinner
        addedStyles={{marginTop: "6px"}}
        variant="extra-small-spinner"
        alignSpin="center-spinner"
      />
    );
  }

  if (isError || !getProfileImage) {
    return <img alt="default" src={DefaultImage} />;
  }

  return (
    <img
      alt=""
      className={TeamBlockStyles.avatarRecipientList}
      src={getProfileImage.profileImage}
    />
  );
};

export default TeamBlockAvatar;
