import {useEffect, useState, Dispatch, SetStateAction} from "react";
import {Socket} from "socket.io-client";
import Search from "../search/Search";
import Notification from "../notifications/Notification";
import Avatar from "../avatar/Avatar";
import ProfileStyles from "./ProfileNav.module.css";

type NavigationProps = {
  socket?: Socket | null;
  setNotificationModal?: Dispatch<SetStateAction<boolean>>;
  setSelectedPostNotification?: Dispatch<SetStateAction<number | null>>;
  setSelectedNotificationId?: Dispatch<SetStateAction<number | null>>;
};

const ProfileNav = ({
  socket,
  setNotificationModal,
  setSelectedPostNotification,
  setSelectedNotificationId,
}: NavigationProps) => {
  const [activeToggle, setActiveToggle] = useState<
    "avatar" | "notification" | null
  >(null);

  useEffect(() => {
    if (activeToggle === "avatar") {
      setActiveToggle("avatar");
    } else if (activeToggle === "notification") {
      setActiveToggle("notification");
    } else {
      setActiveToggle(null);
    }
  }, [activeToggle]);

  return (
    <div className={ProfileStyles.flexBoxBlk}>
      <Search />
      <Notification
        socket={socket}
        setNotificationToogle={() =>
          setActiveToggle(
            activeToggle === "notification" ? null : "notification"
          )
        }
        notificationToggle={activeToggle === "notification"}
        setNotificationModal={setNotificationModal}
        setSelectedPostNotification={setSelectedPostNotification}
        setSelectedNotificationId={setSelectedNotificationId}
      />
      <Avatar
        avatarToggle={activeToggle === "avatar"}
        setAvatarToogle={() =>
          setActiveToggle(activeToggle === "avatar" ? null : "avatar")
        }
      />
    </div>
  );
};

export default ProfileNav;
