import axiosInstance from "./axiosInstance";
import {CreateBadgePostType} from "../types/ComponentProps";

const updateBadge = async (
  badgeId: number,
  badgeDetails: CreateBadgePostType
) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const response = await axiosInstance.patch(
      `/api/badge/${badgeId}`,
      {
        badgeName: badgeDetails.badgeName,
        badgeImage: badgeDetails.badgeImage,
        frequency: badgeDetails.frequency,
        points: badgeDetails.points,
        message: badgeDetails.message,
        hashTagIds: badgeDetails.hashTagIds,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Error updating Badge");
  }
};

export default updateBadge;
