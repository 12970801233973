import {jwtDecode} from "jwt-decode";
import CommentPost from "../commentsModal/CommentPost";
import useGetPostCommentsById from "../../hooks/useGetPostCommentsById";
import Spinner from "../spinner/Spinner";
import {
  PostCardProps,
  CommentPostCardListProps,
  TokenPayload,
} from "../../types/ComponentProps";
import PostCardHeader from "./PostCardHeader";
import PostCardMessage from "./PostCardMessage";
import PostCardFooter from "./PostCardFooter";
import CommentPostCard from "../commentsModal/CommentPostCard";
import PostCardStyles from "./PostCardStyles.module.css";

const PostCard = ({
  post,
  socket,
  setSelectedCard,
  setSelectedCardModal,
  setEditPostModal,
  setEditCardSelection,
}: PostCardProps) => {
  const userDetails = jwtDecode<TokenPayload>(
    JSON.stringify(localStorage.getItem("accessTokenGC"))
  );
  const {
    getPostComments,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useGetPostCommentsById(post?.postId ?? 0);

  const likesModal: React.MouseEventHandler<HTMLParagraphElement> = () => {
    if (setSelectedCard && setSelectedCardModal) {
      setSelectedCardModal(true);
      setSelectedCard(post);
    }
  };

  return (
    <>
      <div className={PostCardStyles.postCardContainer}>
        <PostCardHeader
          userId={post?.userId}
          postId={post?.postId}
          dateCreated={post?.createdAt}
          preferredName={post?.user?.preferredName}
          pronouns={post?.user?.user_profile?.pronouns}
          pointsGiven={post?.pointsGiven}
          points={post?.user?.user_point}
          isAdmin={post?.user?.isAdmin}
          setEditPostModal={setEditPostModal}
          setEditCardSelection={setEditCardSelection}
        />
        <PostCardMessage
          message={post?.message}
          hashTagIds={post?.hashTagIds}
          recipients={post?.recipients}
        />
        {post?.imageURL ? (
          <img
            alt="Uploaded"
            className={PostCardStyles?.uploadedImage}
            src={`${post?.imageURL}`}
          />
        ) : null}
        {post?.likes?.length === 0 || post?.likes === null ? null : post.likes
            .length === 1 && userDetails?.id === post.likes[0]?.id ? (
          <div className={PostCardStyles.likeContainer} onClick={likesModal}>
            <i className="fa-solid fa-thumbs-up"></i>
            <p>You liked this post</p>
          </div>
        ) : (
          <div className={PostCardStyles.likeContainer} onClick={likesModal}>
            <i className="fa-solid fa-thumbs-up"></i>
            <p>{post.likes.length} people like this post</p>
          </div>
        )}
        <PostCardFooter post={post} socket={socket} />
        <hr className={PostCardStyles.hrStyles} />
        {isLoading ? (
          <Spinner variant="medium-spinner" alignSpin="center-spinner" />
        ) : getPostComments?.pages?.flatMap((page) => page.comments).length ===
          0 ? (
          <p className={PostCardStyles.beTheFirstSectionBlk}>
            Be the first to comment on this post.
          </p>
        ) : (
          getPostComments?.pages?.flatMap((page) =>
            page.comments.map((data: CommentPostCardListProps["comments"]) => (
              <CommentPostCard key={data.commentId} data={data} />
            ))
          )
        )}
        {isFetchingNextPage ? (
          <Spinner variant="small-spinner" alignSpin="center-spinner" />
        ) : null}
        {getPostComments?.pages[0]?.pagination?.totalItems > 3 &&
          hasNextPage && (
            <div className={PostCardStyles.showAllCommentsSection}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  fetchNextPage();
                }}
                disabled={isFetchingNextPage}>
                {isFetchingNextPage ? null : "View previous comments"}
              </button>
            </div>
          )}
        <CommentPost post={post} socket={socket} />
      </div>
    </>
  );
};

export default PostCard;
