import {useState} from "react";
import SpaceTab from "./SpaceTab";
import MentionTab from "./MentionTab";
import LikeTab from "./LikeTab";
import ProfileTabStyles from "./ProfileTabStyles.module.css";

const ProfileTab = () => {
  const [activeTab, setActiveTab] = useState("spaceTab");

  const renderContent = () => {
    switch (activeTab) {
      case "spaceTab":
        return <SpaceTab />;
      case "mentionTab":
        return <MentionTab />;
      case "likesTab":
        return <LikeTab />;
      default:
        return null;
    }
  };

  return (
    <div className={ProfileTabStyles.profileMainContainer}>
      <div className={ProfileTabStyles.profileTabContainer}>
        <div
          className={`${ProfileTabStyles.tabProfile} ${
            activeTab === "spaceTab" ? `${ProfileTabStyles.activeTab}` : ""
          }`}
          onClick={() => setActiveTab("spaceTab")}>
          Space
        </div>
        <div
          className={`${ProfileTabStyles.tabProfile} ${
            activeTab === "mentionTab" ? `${ProfileTabStyles.activeTab}` : ""
          }`}
          onClick={() => setActiveTab("mentionTab")}>
          Mentioned
        </div>
        <div
          className={`${ProfileTabStyles.tabProfile} ${
            activeTab === "likesTab" ? `${ProfileTabStyles.activeTab}` : ""
          }`}
          onClick={() => setActiveTab("likesTab")}>
          Your Likes
        </div>
      </div>
      <hr className={ProfileTabStyles.hrSectionBlk} />
      <div className="tab-content">{renderContent()}</div>
    </div>
  );
};

export default ProfileTab;
