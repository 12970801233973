import axiosInstance from "./axiosInstance";
import {LoginUserProps} from "../types/ComponentProps";

const loginUser = async (formData: LoginUserProps) => {
  try {
    const {data} = await axiosInstance.post("/api/login", formData);
    localStorage.setItem("accessTokenGC", data?.accessToken);
    return data;
  } catch (error) {
    throw new Error("Error");
  }
};

export default loginUser;
