import {Dispatch, SetStateAction} from "react";
import {Socket} from "socket.io-client";
import Navigation from "../navigation/Navigation";

type WithNavTemplateProps = {
  children: React.ReactNode;
  socket?: Socket | null;
  setNotificationModal?: Dispatch<SetStateAction<boolean>>;
  setSelectedPostNotification?: Dispatch<SetStateAction<number | null>>;
  setSelectedNotificationId?: Dispatch<SetStateAction<number | null>>;
};

const WithNavTemplate = ({
  children,
  socket,
  setNotificationModal,
  setSelectedPostNotification,
  setSelectedNotificationId,
}: WithNavTemplateProps) => {
  return (
    <div className="dashboard-container-blk">
      <Navigation
        socket={socket}
        setNotificationModal={setNotificationModal}
        setSelectedPostNotification={setSelectedPostNotification}
        setSelectedNotificationId={setSelectedNotificationId}
      />
      {children}
    </div>
  );
};

export default WithNavTemplate;
