import {useQuery} from "@tanstack/react-query";
import getAllPostLikes from "../api/getAllPostLikes";

const useGetAllPostLikes = (postId: number) => {
  const {
    data: likes,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["getAllPostLikes", postId],
    queryFn: () => getAllPostLikes(postId),
  });
  return {isLoading, likes, isError, error};
};

export default useGetAllPostLikes;
