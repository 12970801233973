import {useEffect, useState, useRef} from "react";
import {useQueryClient, useMutation} from "@tanstack/react-query";
import Message from "../message/Message";
import Spinner from "../spinner/Spinner";
import InputNumber from "../inputNumber/InputNumber";
import CustomSelect from "../customSelect/CustomSelect";
import CustomSelectHashTag from "../customSelectHashTag/CustomSelectHashTag";
import Input from "../input/Input";
import {HashTagPage} from "../../types/ComponentProps";
import {
  BadgeUpdateProps,
  CreateBadgePostType,
  HashTagListProps,
  CustomSelectHashTagType,
  CustomSelectOption,
} from "../../types/ComponentProps";
import useGetAllHashTag from "../../hooks/useGetAllHashTag";
import useGetBadgeById from "../../hooks/useGetBadgeById";
import updateBadge from "../../api/updateBadge";
import {badgeFrequency} from "../badgeModal/dropdown";
import BadgeListStyles from "./BadgeListStyles.module.css";

const BadgeUpdate = ({
  setModals,
  selectedBadge,
  setIsBadgeListLoading,
}: BadgeUpdateProps) => {
  const queryClient = useQueryClient();

  const {getAllDataHashTag, hasNextPage, fetchNextPage} = useGetAllHashTag();
  const {getBadgeByIdData, isLoading} = useGetBadgeById(selectedBadge ?? 0);

  const initialState: CreateBadgePostType = {
    badgeName: "",
    badgeImage: null,
    points: null,
    frequency: "",
    hashTagIds: [],
    message: "",
  };

  const [createBadgeForm, setCreateBadgeForm] =
    useState<CreateBadgePostType>(initialState);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [updateBadgeLoading, setUpdateCreateBadgeLoading] =
    useState<boolean>(false);
  const [formValidation, setFormValidation] = useState<boolean>(false);

  useEffect(() => {
    if (getBadgeByIdData?.badge) {
      setCreateBadgeForm({
        badgeName: getBadgeByIdData?.badge?.badgeName,
        badgeImage: null,
        points: getBadgeByIdData?.badge?.points,
        frequency: getBadgeByIdData?.badge?.frequency,
        hashTagIds: getBadgeByIdData?.badge?.hashTagIds || [],
        message: getBadgeByIdData?.badge?.message,
      });
    }
  }, [getBadgeByIdData]);

  useEffect(() => {
    if (createBadgeForm.badgeImage) {
      const imageUrl = URL.createObjectURL(createBadgeForm.badgeImage);
      setImagePreview(imageUrl);
      return () => URL.revokeObjectURL(imageUrl);
    }
    setImagePreview(null);
  }, [createBadgeForm.badgeImage]);

  const updateBadgeMutation = useMutation({
    mutationFn: () => updateBadge(Number(selectedBadge ?? 0), createBadgeForm),
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({queryKey: ["getAllBadges"]});
        await queryClient.invalidateQueries({
          queryKey: ["getBadgeById", selectedBadge],
        });
      } catch (error) {
        console.error("Error invalidating queries:", error);
      } finally {
        if (setIsBadgeListLoading) {
          setIsBadgeListLoading(false);
        }
        setUpdateCreateBadgeLoading(false);
        setModals((prev) => ({
          ...prev,
          edit: false,
        }));
      }
    },
    onError: (error: Error) => {
      console.error("Error create database:", error);
    },
  });

  const onUpdateBadgeClickHandler = async () => {
    const {badgeName, points, frequency, hashTagIds, message} =
      createBadgeForm || {};

    const isFormIncomplete =
      [badgeName, points, frequency, message].some((field) => !field) ||
      frequency === "Blank" ||
      !hashTagIds ||
      (Array.isArray(hashTagIds) && hashTagIds.length === 0);

    if (isFormIncomplete) {
      return setFormValidation(true);
    }

    if (setIsBadgeListLoading) {
      setIsBadgeListLoading(true);
    }
    setUpdateCreateBadgeLoading(true);
    updateBadgeMutation.mutate();
  };

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = e.target;
    setCreateBadgeForm((previous) => ({
      ...previous,
      [name]: value,
    }));
    setFormValidation(false);
  };

  const onCloseEditModalHandler = () => {
    setModals((prev) => ({
      ...prev,
      edit: false,
    }));
  };

  const imageUploadHandler = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (validImageTypes.includes(file.type)) {
        setCreateBadgeForm((previous) => ({
          ...previous,
          badgeImage: file,
        }));
      }
    }
    setFormValidation(false);
  };

  const closeImagePreviewClickHandler = () => {
    setImagePreview(null);
    setCreateBadgeForm((previous) => ({
      ...previous,
      badgeImage: null,
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSelect =
    (field: "frequency") => (selectedOption: CustomSelectOption) => {
      setCreateBadgeForm((prevForm) => ({
        ...prevForm,
        [field]: selectedOption.value,
      }));
      setFormValidation(false);
    };

  const handleSelectHashTag =
    (field: "hashTagIds") => (selectedOption: CustomSelectHashTagType) => {
      setCreateBadgeForm((prevForm: CreateBadgePostType) => ({
        ...prevForm,
        [field]: [
          ...(prevForm?.hashTagIds || []),
          {
            hashTagId: selectedOption?.hashTagId,
            hashTagName: selectedOption?.hashTagName,
          },
        ],
      }));
      setFormValidation(false);
    };

  const removeListHashTag = (hashTagId: number) => {
    setCreateBadgeForm((prevForm: CreateBadgePostType) => ({
      ...prevForm,
      hashTagIds:
        prevForm.hashTagIds?.filter(
          (tag: HashTagListProps) => Number(tag.hashTagId) !== Number(hashTagId)
        ) || [],
    }));
  };

  const filteredHashTags =
    getAllDataHashTag?.pages
      .flatMap((page: HashTagPage) => page.hashTags)
      .filter(
        (hashTag: CustomSelectHashTagType) =>
          !createBadgeForm?.hashTagIds?.some(
            (selectedTag: HashTagListProps) =>
              Number(selectedTag?.hashTagId) === Number(hashTag.hashTagId)
          )
      ) || [];

  return (
    <div className={BadgeListStyles.updateBadgeModal}>
      <div className={BadgeListStyles.updateBadgePromptMainContainer}>
        {isLoading ? (
          <div style={{padding: "10px"}}>
            <Spinner variant="medium-spinner" alignSpin="center-spinner" />
          </div>
        ) : (
          <div
            className={BadgeListStyles.scrollContainer}
            style={
              !createBadgeForm?.hashTagIds?.length
                ? {overflow: "hidden", paddingRight: "0px"}
                : {overflowY: "scroll", paddingRight: "10px"}
            }>
            <div className={BadgeListStyles.updateFlexBoxBlk}>
              <div>
                <h1>Update Badge</h1>
              </div>
              <div>
                <span
                  className={`fa-solid fa-xmark ${BadgeListStyles.closeIconUpdateBadge}`}
                  onClick={onCloseEditModalHandler}></span>
              </div>
            </div>
            <hr className={BadgeListStyles.updateHorizontalLine} />
            <div className={BadgeListStyles.basicSettingsFieldSection}>
              <div className={BadgeListStyles.basicSettingsFlexBoxBlk}>
                <div>
                  <Input
                    type="text"
                    value={createBadgeForm?.badgeName || ""}
                    name="badgeName"
                    onChangeHandler={onChangeHandler}
                    label="Badge Name"
                  />
                </div>
                <div>
                  <div className={BadgeListStyles.imageUploadSection}>
                    <label>Badge Icon</label>
                    <div className={BadgeListStyles.uploadSectionFlexBox}>
                      <div>
                        {!imagePreview ? (
                          <img
                            src={getBadgeByIdData?.badge?.imageURL}
                            alt="Preview"
                            className={BadgeListStyles.badgeImageContainer}
                          />
                        ) : null}
                        {imagePreview && (
                          <>
                            {imagePreview && (
                              <div
                                className={BadgeListStyles.closeIconBlock}
                                onClick={closeImagePreviewClickHandler}>
                                <i className="fa-solid fa-xmark"></i>
                              </div>
                            )}
                            <img
                              src={imagePreview}
                              alt="Preview"
                              className={BadgeListStyles.badgeImageContainer}
                            />
                          </>
                        )}
                      </div>
                      <div onClick={imageUploadHandler}>
                        {imagePreview ? (
                          <p className={BadgeListStyles.uploadedFileName}>
                            {createBadgeForm?.badgeImage?.name}
                          </p>
                        ) : (
                          <>
                            <p className={BadgeListStyles.uploadBlurb}>
                              Upload new badge
                            </p>
                            <p className={BadgeListStyles.fileFormatBlurb}>
                              Formats JPEG, JPG, PNG, and GIF
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      name="badgeImage"
                      style={{display: "none"}}
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>

              <div className={BadgeListStyles.basicSettingsFlexBoxBlk}>
                <div>
                  <InputNumber
                    type="number"
                    value={
                      createBadgeForm.points !== null
                        ? String(createBadgeForm.points)
                        : ""
                    }
                    name="points"
                    onChangeHandler={onChangeHandler}
                    label="Points"
                  />
                </div>
                <div>
                  <label>Frequency</label>
                  <CustomSelect
                    options={badgeFrequency}
                    onSelect={handleSelect("frequency")}
                    placeholder={
                      createBadgeForm?.frequency === null
                        ? "Select Frequency"
                        : String(createBadgeForm?.frequency)
                    }
                  />
                </div>
              </div>

              <div className={BadgeListStyles.basicSettingsFlexBoxBlk}>
                <div>
                  <label>Hash Tags</label>
                  <CustomSelectHashTag
                    options={filteredHashTags}
                    onSelect={handleSelectHashTag("hashTagIds")}
                    placeholder="Select HashTags"
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                  />
                </div>
              </div>

              {createBadgeForm?.hashTagIds?.length === 0 ? null : (
                <div className={BadgeListStyles.hashTagNameFlex}>
                  {createBadgeForm?.hashTagIds?.map(
                    (data: HashTagListProps, index: number) => {
                      return (
                        <h1 key={index} className={BadgeListStyles.hashTagName}>
                          <p>#{data?.hashTagName} </p>
                          <p
                            onClick={() =>
                              removeListHashTag(Number(data?.hashTagId))
                            }>
                            x
                          </p>
                        </h1>
                      );
                    }
                  )}
                </div>
              )}
              <div style={{marginTop: "20px"}}>
                <Input
                  type="text"
                  value={createBadgeForm.message}
                  name="message"
                  onChangeHandler={onChangeHandler}
                  label="Message"
                />
              </div>
              {formValidation ? (
                <div style={{margin: "10px 0px"}}>
                  <Message variant="error">
                    All fields must be completed to proceed
                  </Message>
                </div>
              ) : null}
              <button
                className={BadgeListStyles.buttonUpdateBadge}
                onClick={onUpdateBadgeClickHandler}>
                {updateBadgeLoading ? (
                  <Spinner variant="small-spinner" alignSpin="center-spinner" />
                ) : (
                  <>
                    Update badge<i className="fa-solid fa-arrow-right"></i>
                  </>
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BadgeUpdate;
