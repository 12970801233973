import useGetProfilePicture from "../../hooks/useGetProfilePicture";
import DefaultImage from "../../assets/img/default-profile-photo.png";
import Spinner from "../spinner/Spinner";
import CommentsModalStyles from "./CommentsModalStyles.module.css";
import {AvatarLikeProps} from "../../types/ComponentProps";

const CommentAvatarList = ({userId}: AvatarLikeProps) => {
  const {isLoading, isFetching, isError, getProfileImage} =
    useGetProfilePicture(userId);

  if (isLoading || isFetching) {
    return (
      <Spinner
        addedStyles={{marginTop: "6px"}}
        variant="extra-small-spinner"
        alignSpin="center-spinner"
      />
    );
  }

  if (isError || !getProfileImage) {
    return (
      <img
        alt="default"
        className={CommentsModalStyles.avatarSectionListCommentBlk}
        src={DefaultImage}
      />
    );
  }

  return (
    <img
      alt="Profile"
      className={CommentsModalStyles.avatarSectionListCommentBlk}
      src={getProfileImage.profileImage}
    />
  );
};

export default CommentAvatarList;
