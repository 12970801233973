import axiosInstance from "./axiosInstance";

const updatePost = async (postId: number, formPost: any) => {
  const accessToken = localStorage.getItem("accessTokenGC");

  try {
    const response = await axiosInstance.patch(
      `/api/post/${postId}`,
      formPost,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Error updating comment");
  }
};

export default updatePost;
