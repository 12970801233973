import Spinner from "../spinner/Spinner";
import ModalIcon from "../../assets/img/dashboard/modal-icon.png";
import {ModalProps} from "../../types/ComponentProps";
import ModalStyles from "./ModalStyles.module.css";

const Modal = ({
  title,
  modalOpen,
  onSubmitHandler,
  setDeleteModal,
  isLoading,
}: ModalProps) => {
  if (!modalOpen) return null;
  return (
    <div className={ModalStyles.modalContainer}>
      <div className={ModalStyles.modalMainBlk}>
        <i
          onClick={!isLoading ? () => setDeleteModal(false) : undefined}
          className={`fa-solid fa-xmark ${
            isLoading ? ModalStyles.disabled : ""
          }`}></i>
        <img src={ModalIcon} alt="Modal icon" />
        <h1>{title}</h1>
        <button onClick={onSubmitHandler}>
          {isLoading ? (
            <Spinner alignSpin="center-spinner" variant="small-spinner" />
          ) : (
            "YES"
          )}
        </button>
      </div>
    </div>
  );
};

export default Modal;
