import {useQuery} from "@tanstack/react-query";
import getNotificationById from "../api/getNotificationById";

const useGetAllNotification = () => {
  const {
    data: getNotificationByIdData,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ["getNotificationById"],
    queryFn: getNotificationById,
    refetchOnWindowFocus: false,
  });
  return {isLoading, getNotificationByIdData, isError, error, refetch};
};

export default useGetAllNotification;
