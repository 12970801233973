import axiosInstance from "./axiosInstance";

const getAllHashTagList = async () => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const {data} = await axiosInstance.get(`/api/getAllHashTagList`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (error) {
    throw new Error("Error fetching user points.");
  }
};

export default getAllHashTagList;
