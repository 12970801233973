import {useInfiniteQuery} from "@tanstack/react-query";
import getPostCommentsById from "../api/getPostCommentById";

const useGetPostCommentsById = (postId: number) => {
  const {
    data: getPostComments,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["getPostComments", postId],
    queryFn: ({pageParam = 1}) => getPostCommentsById(postId, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const {pagination} = lastPage;
      if (pagination.currentPage < pagination.totalPages) {
        return pagination.currentPage + 1;
      }
      return undefined;
    },
    refetchOnWindowFocus: false,
  });

  return {
    isLoading,
    getPostComments,
    isError,
    error,
    refetch,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useGetPostCommentsById;
