import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Quote from "inspirational-quotes";
import {loginRequest} from "../config/authConfig";
import {useMsal} from "@azure/msal-react";
import loginUser from "../api/loginUser";
import Spinner from "../components/spinner/Spinner";

/* Image */
import ModalIcon from "../assets/img/dashboard/modal-icon.png";
import logoImage from "../assets/img/login/logo.png";
import planetOneHeader from "../assets/img/login/planet-header-one-image.png";
import planetTwoHeader from "../assets/img/login/planet-header-two-image.png";
import planetThreeHeader from "../assets/img/login/planet-header-three-image.png";
import planetMidImage from "../assets/img/login/planet-mid-image.png";
import planetOneFooter from "../assets/img/login/planet-footer-one-image.png";
import planetTwoFooter from "../assets/img/login/planet-footer-two-image.png";
import planetThtreeFooter from "../assets/img/login/planet-footer-three-image.png";
import astronautImage from "../assets/img/login/astronaut-image.png";
import {useQuery} from "@tanstack/react-query";

const LoginPage = () => {
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState<boolean>(false);
  const {text, author} = Quote.getQuote();
  const {instance, accounts} = useMsal();
  const isAuthenticated = accounts.length > 0;

  const {data, error, isLoading} = useQuery({
    queryKey: ["login", isAuthenticated, accounts[0]?.username],
    queryFn: () => loginUser({email: accounts[0]?.username}),
    enabled: isAuthenticated && !!accounts[0]?.username,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const onLoginHandler = async () => {
    try {
      await instance.loginPopup(loginRequest);
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes("user_cancelled")) {
          console.warn("Login was cancelled by the user.");
        } else {
          console.error("Login failed:", error);
        }
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
  };

  const closePromptHandler = async () => {
    setPrompt(false);
  };

  useEffect(() => {
    if (data?.success) {
      setTimeout(() => {
        navigate("/");
      }, 500);
    }
  }, [data?.success, navigate]);

  useEffect(() => {
    if (isAuthenticated && error) {
      sessionStorage.clear();
      localStorage.clear();
      return setPrompt(true);
    }
  }, [error, isAuthenticated]);

  return (
    <div id="login-page-container">
      <section id="logo-section-blk">
        <img src={logoImage} className="logo-image" alt="iCXeed Logo" />
      </section>
      <img
        src={planetOneHeader}
        className="planet-header-one-image"
        alt="Planet"
      />
      <img
        src={planetTwoHeader}
        className="planet-header-two-image"
        alt="Planet"
      />
      <img
        src={planetThreeHeader}
        className="planet-header-three-image"
        alt="Planet"
      />
      <img src={planetMidImage} className="planet-mid-image" alt="Planet" />
      <section id="quote-section-blk">
        <p>"{text}"</p>
        <p>- {author} -</p>
      </section>
      <img
        src={planetOneFooter}
        className="planet-footer-one-image"
        alt="Planet"
      />
      <img
        src={planetTwoFooter}
        className="planet-footer-two-image"
        alt="Planet"
      />
      <img
        src={planetThtreeFooter}
        className="planet-footer-three-image"
        alt="Planet"
      />
      <img src={astronautImage} className="astronaut-image" alt="Astronaut" />
      <button onClick={onLoginHandler} disabled={isLoading}>
        {isLoading ? (
          <Spinner variant="small-spinner" alignSpin="center-spinner" />
        ) : (
          "Sign In"
        )}
      </button>
      {prompt ? (
        <div className="promot-message-login-page-error">
          <div>
            <div className="prompt-close-icon" onClick={closePromptHandler}>
              <i className="fa-solid fa-xmark"></i>
            </div>
            <img src={ModalIcon} alt="Modal icon" />
            <h1>
              Your Game Changer account is not yet set up.
              <br />
              Contact the administrator for assistance.
            </h1>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LoginPage;
