import {useInfiniteQuery} from "@tanstack/react-query";
import getAllBadges from "../api/getAllBadges";

const useGetAllBadges = () => {
  const {
    data: getAllBadgesData,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["getAllBadges"],
    queryFn: getAllBadges,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage <= lastPage.totalPages
        ? lastPage.nextPage
        : undefined;
    },
    refetchOnWindowFocus: false,
  });

  return {
    isLoading,
    getAllBadgesData,
    isError,
    error,
    refetch,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useGetAllBadges;
