import ProgressBar from "../progressBar/ProgressBar";
import LevelBlockStyles from "./LevelBlockStyles.module.css";
import PlanetLevelBg from "../../assets/img/dashboard/levels-section-bg.png";
import PlanetLevel from "../../assets/img/dashboard/planet-levels.png";
import {LevelBlockProps} from "../../types/ComponentProps";
import {getLevel} from "../../utils/getLevel";
import AsteroidAdventurer from "../../assets/img/dashboard/level-badge/asteroid-adventurer-avatar.png";
import CosmicCatalyst from "../../assets/img/dashboard/level-badge/cosmic-catalyst-avatar.png";
import ETExplorer from "../../assets/img/dashboard/level-badge/extraterrestrial-explorer-avatar.png";
import FusionForeRunner from "../../assets/img/dashboard/level-badge/fusion-forerunner-avatar.png";
import GalacticGroundbreaker from "../../assets/img/dashboard/level-badge/galactic-groundbreaker-avatar.png";
import InterplanetaryIconoclast from "../../assets/img/dashboard/level-badge/interplanetary-iconocolast-avatar.png";
import InterstellarInitiator from "../../assets/img/dashboard/level-badge/interstellar-initiator-avatar.png";
import IntergalacticInnovator from "../../assets/img/dashboard/level-badge/intergalactic-innovator-avatar.png";
import InterCelestialInventor from "../../assets/img/dashboard/level-badge/intercelestial-inventor-avatar.png";
import LunarLeader from "../../assets/img/dashboard/level-badge/lunar-leader-avatar.png";
import LightYearLuminary from "../../assets/img/dashboard/level-badge/light-year-luminary-avatar.png";
import NebularNavigator from "../../assets/img/dashboard/level-badge/nebular-navigator-avatar.png";
import PlanetaryPathfinder from "../../assets/img/dashboard/level-badge/planetary-pathfinder-avatar.png";
import PulsarPioneer from "../../assets/img/dashboard/level-badge/pulsar-pioneer-avatar.png";
import StardustTrailblazer from "../../assets/img/dashboard/level-badge/stardust-trailblazer-avatar.png";
import StellarTrendsetter from "../../assets/img/dashboard/level-badge/stellar-trendsetter-avatar.png";
import SupernovaVanguard from "../../assets/img/dashboard/level-badge/supernova-vanguard-avatar.png";
import UltravioletVisionary from "../../assets/img/dashboard/level-badge/ultraviolet-visionary-avatar.png";
import ZeroGWayfarer from "../../assets/img/dashboard/level-badge/zero-g-wayfarer-avatar.png";

const levelImages: Record<string, string> = {
  "Asteroid Adventurer": AsteroidAdventurer,
  "Cosmic Catalyst": CosmicCatalyst,
  "Extraterrestrial Explorer": ETExplorer,
  "Fusion Forerunner": FusionForeRunner,
  "Galactic Groundbreaker": GalacticGroundbreaker,
  "Interplanetary Iconoclast": InterplanetaryIconoclast,
  "Interstellar Initiator": InterstellarInitiator,
  "Intergalactic Innovator": IntergalacticInnovator,
  "Inter-celestial Inventor": InterCelestialInventor,
  "Lunar Leader": LunarLeader,
  "Light-year Luminary": LightYearLuminary,
  "Nebular Navigator": NebularNavigator,
  "Planetary Pathfinder": PlanetaryPathfinder,
  "Pulsar Pioneer": PulsarPioneer,
  "Stardust Trailblazer": StardustTrailblazer,
  "Stellar Trendsetter": StellarTrendsetter,
  "Supernova Vanguard": SupernovaVanguard,
  "Ultraviolet Visionary": UltravioletVisionary,
  "Zero-G Wayfarer": ZeroGWayfarer,
};

const LevelBlock: React.FC<LevelBlockProps> = ({data}) => {
  const {points} = data || {};
  const levelName = getLevel(points?.points);
  const levelImage = levelImages[levelName?.level || ""];
  const levelIndex = levelName?.index != null ? levelName.index + 1 : null;

  const percentage: number =
    points?.points === 0 ? 0 : levelName?.percentage ?? 0;

  return (
    <div className={LevelBlockStyles.levelContainer}>
      <img
        alt="Planet One"
        className={LevelBlockStyles.planetLevelImageBg}
        src={PlanetLevel}
      />
      <img
        alt="Planet Two"
        className={LevelBlockStyles.planetLevelIBg}
        src={PlanetLevelBg}
      />

      <div className={LevelBlockStyles.levelDescriptionBlk}>
        <h1>My Level</h1>
        <div className={LevelBlockStyles.flexBox}>
          {levelImage && (
            <div>
              <img alt="Level Avatar" src={levelImage} />
            </div>
          )}
          <div>
            <h2>Level {levelIndex}</h2>
            <p>{levelName?.level}</p>
            <ProgressBar width={percentage} points={points?.points} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LevelBlock;
