import {useQuery} from "@tanstack/react-query";
import getAllHashTagList from "../api/getAllHashTagList";

const useGetAllHashTagList = () => {
  const {
    data: getAllHashTagListData,
    isLoading: isLoadingHashTagListData,
    isError: isErrorHashTagListData,
  } = useQuery({
    queryKey: ["getAllHashTagListDashboard"],
    queryFn: getAllHashTagList,
    refetchOnWindowFocus: false,
  });
  return {
    isLoadingHashTagListData,
    getAllHashTagListData,
    isErrorHashTagListData,
  };
};

export default useGetAllHashTagList;
