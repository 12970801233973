import React from "react";
import PhoneInputStyles from "./PhoneInputStyles.module.css";

type InputProps = {
  type?: string;
  value?: string;
  name?: string;
  label?: string;
  onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
};

const PhoneInput = ({
  type,
  value,
  name,
  label,
  onChangeHandler,
  placeholder,
}: InputProps) => {
  return (
    <>
      {label ? (
        <label className={PhoneInputStyles.label} htmlFor={name}>
          {label}
        </label>
      ) : null}
      <div className={PhoneInputStyles.labelInputMobilePhone}>
        <div className={PhoneInputStyles.testing}>+63</div>
        <input
          className={PhoneInputStyles.input}
          type={type}
          maxLength={10}
          value={value}
          name={name}
          onChange={onChangeHandler}
          placeholder={placeholder}
          autoComplete="off"
        />
      </div>
    </>
  );
};

export default PhoneInput;
