const dateFormatter = (dateString: string | Date) => {
  const currentDate = new Date();
  const targetDate = new Date(dateString);

  const isSameDay = (date1: Date, date2: Date) =>
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

  const isYesterday = (date: Date) => {
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    return isSameDay(date, yesterday);
  };

  const getDaysDifference = (d1: Date, d2: Date) => {
    const date1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
    const date2 = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate());
    const difference =
      (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24);
    return Math.round(difference);
  };

  if (isSameDay(currentDate, targetDate)) {
    return "Today";
  } else if (isYesterday(targetDate)) {
    return "Yesterday";
  } else {
    const daysAgo = getDaysDifference(currentDate, targetDate);

    if (daysAgo < 7) {
      return `${daysAgo} day${daysAgo !== 1 ? "s" : ""} ago`;
    } else {
      const options: Intl.DateTimeFormatOptions = {
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };
      const formattedDate = targetDate.toLocaleString("en-US", options);
      return formattedDate.replace(",", " •");
    }
  }
};

export default dateFormatter;
