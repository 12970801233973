import {PostBlockProps, HashTagListProps} from "../../types/ComponentProps";
import PostBlockStyles from "./PostBlockStyles.module.css";

const PostBlockTextArea = ({
  formPost,
  onChangeHandler,
  selectedHashTag,
  setSelectedHashTag,
}: PostBlockProps) => {
  const onHashTagDeleteList = (hashTagId: number) => {
    if (setSelectedHashTag) {
      setSelectedHashTag((previous) => {
        const updatedHashTags = previous.filter(
          (tag: HashTagListProps) => tag.hashTagId !== hashTagId
        );
        return updatedHashTags;
      });
    }
  };
  return (
    <div>
      <textarea
        onChange={onChangeHandler}
        name="message"
        value={formPost?.message}
        placeholder="What's on your mind?"
        spellCheck="false"
        maxLength={1024}
      />
      <div className={PostBlockStyles.flexBoxSectionhashTagSectionPost}>
        {selectedHashTag?.map((data: HashTagListProps, index: number) => {
          return (
            <h1 key={index} className={PostBlockStyles.hashTagSectionPostH1}>
              #{data?.hashTagName}{" "}
              <span onClick={() => onHashTagDeleteList(data?.hashTagId ?? 0)}>
                &times;
              </span>
            </h1>
          );
        })}
      </div>
    </div>
  );
};

export default PostBlockTextArea;
