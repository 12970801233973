import {useEffect, useState, forwardRef} from "react";
import DatePicker from "react-datepicker";
import {
  DatePickerComponentProps,
  CreateUserProps,
} from "../../types/ComponentProps";
import DatePickerComponentStyles from "./DatePickerComponentStyles.module.css";
import "react-datepicker/dist/react-datepicker.css";

interface CustomInputProps {
  value?: string;
  onClick?: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DatePickerComponent = ({
  setFormInput,
  resetDate,
  birthday,
}: DatePickerComponentProps & {
  resetDate?: boolean;
  birthday?: string;
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const today = new Date();
  const dateRegex = /^\d{2}-\d{2}-\d{4}$/; // MM-dd-yyyy

  useEffect(() => {
    if (birthday) {
      const date = new Date(birthday);
      if (!isNaN(date.getTime())) {
        setSelectedDate(date);
      }
    } else {
      setSelectedDate(null);
    }
  }, [birthday]);

  useEffect(() => {
    if (setFormInput) {
      setFormInput((previous: CreateUserProps) => ({
        ...previous,
        birthday: selectedDate ? formatDateToLocal(selectedDate) : "",
      }));
    }
  }, [selectedDate, setFormInput]);

  useEffect(() => {
    if (resetDate) {
      setSelectedDate(null);
    }
  }, [resetDate]);

  const formatDateToLocal = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
    ({value, onClick, onChange}, ref) => (
      <input
        ref={ref}
        onClick={onClick}
        value={value}
        onChange={(e) => {
          const inputValue = e.target.value;
          // Only update value if it matches the MM-dd-yyyy format
          if (dateRegex.test(inputValue) || inputValue === "") {
            onChange(e);
          }
        }}
        placeholder="MM-DD-YYYY"
        className={DatePickerComponentStyles.customDatePickerInput}
      />
    )
  );

  return (
    <div className={DatePickerComponentStyles.datePickerContainer}>
      <DatePicker
        selected={selectedDate}
        onChange={(date: Date | null) => setSelectedDate(date)}
        showYearDropdown
        maxDate={today}
        dateFormat="MM-dd-yyyy"
        customInput={
          <CustomInput
            value={selectedDate ? formatDateToLocal(selectedDate) : ""}
            onChange={() => {}}
          />
        }
      />
    </div>
  );
};

export default DatePickerComponent;
