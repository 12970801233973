export const pronounOptions = [
  {value: "She/Her", label: "She/Her"},
  {value: "He/Him", label: "He/Him"},
  {value: "They/Them", label: "They/Them"},
  {value: "Ze/Zir", label: "Ze/Zir"},
  {value: "blank", label: "Do not specify"},
];

export const provinceOptions = [
  {value: "", label: "Select a Province"},
  {value: "Abra", label: "Abra"},
  {value: "Agusan", label: "Agusan"},
  {value: "Aklan", label: "Aklan"},
  {value: "Albay", label: "Albay"},
  {value: "Antique", label: "Antique"},
  {value: "Apayao", label: "Apayao"},
  {value: "Aurora", label: "Aurora"},
  {value: "Basilan", label: "Basilan"},
  {value: "Bataan", label: "Bataan"},
  {value: "Batanes", label: "Batanes"},
  {value: "Batangas", label: "Batangas"},
  {value: "Benguet", label: "Benguet"},
  {value: "Biliran", label: "Biliran"},
  {value: "Bohol", label: "Bohol"},
  {value: "Bukidnon", label: "Bukidnon"},
  {value: "Bulacan", label: "Bulacan"},
  {value: "Cagayan", label: "Cagayan"},
  {value: "Camarines", label: "Camarines"},
  {value: "Camiguin", label: "Camiguin"},
  {value: "Capiz", label: "Capiz"},
  {value: "Catanduanes", label: "Catanduanes"},
  {value: "Cavite", label: "Cavite"},
  {value: "Cebu", label: "Cebu"},
  {value: "Cotabato", label: "Cotabato"},
  {value: "Davao", label: "Davao"},
  {value: "Dinagat Islands", label: "Dinagat Islands"},
  {value: "Guimaras", label: "Guimaras"},
  {value: "Ifugao", label: "Ifugao"},
  {value: "Ilocos", label: "Ilocos"},
  {value: "Iloilo", label: "Iloilo"},
  {value: "Isabela", label: "Isabela"},
  {value: "Kalinga", label: "Kalinga"},
  {value: "La Union", label: "La Union"},
  {value: "Laguna", label: "Laguna"},
  {value: "Lanao del Norte", label: "Lanao del Norte"},
  {value: "Lanao del Sur", label: "Lanao del Sur"},
  {value: "Leyte", label: "Leyte"},
  {value: "Maguindanao", label: "Maguindanao"},
  {value: "Marinduque", label: "Marinduque"},
  {value: "Masbate", label: "Masbate"},
  {value: "Metro Manila", label: "Metro Manila"},
  {value: "Mindoro", label: "Mindoro"},
  {value: "Misamis Occidental", label: "Misamis Occidental"},
  {value: "Misamis Oriental", label: "Misamis Oriental"},
  {value: "Mountain Province", label: "Mountain Province"},
  {value: "Negros Occidental", label: "Negros Occidental"},
  {value: "Negros Oriental", label: "Negros Oriental"},
  {value: "Northern Samar", label: "Northern Samar"},
  {value: "Nueva Ecija", label: "Nueva Ecija"},
  {value: "Nueva Vizcaya", label: "Nueva Vizcaya"},
  {value: "Palawan", label: "Palawan"},
  {value: "Pampanga", label: "Pampanga"},
  {value: "Pangasinan", label: "Pangasinan"},
  {value: "Quezon", label: "Quezon"},
  {value: "Quirino", label: "Quirino"},
  {value: "Rizal", label: "Rizal"},
  {value: "Romblon", label: "Romblon"},
  {value: "Samar", label: "Samar"},
  {value: "Sarangani", label: "Sarangani"},
  {value: "Siquijor", label: "Siquijor"},
  {value: "Sorsogon", label: "Sorsogon"},
  {value: "South Cotabato", label: "South Cotabato"},
  {value: "Sultan Kudarat", label: "Sultan Kudarat"},
  {value: "Sulu", label: "Sulu"},
  {value: "Surigao del Norte", label: "Surigao del Norte"},
  {value: "Surigao del Sur", label: "Surigao del Sur"},
  {value: "Tarlac", label: "Tarlac"},
  {value: "Tawi-Tawi", label: "Tawi-Tawi"},
  {value: "Zambales", label: "Zambales"},
  {value: "Zamboanga", label: "Zamboanga"},
];

export const cityOptions = [
  {value: "Manila", label: "Manila"},
  {value: "Quezon City", label: "Quezon City"},
  {value: "Caloocan", label: "Caloocan"},
  {value: "Davao City", label: "Davao City"},
  {value: "Cebu City", label: "Cebu City"},
  {value: "Zamboanga City", label: "Zamboanga City"},
  {value: "Taguig", label: "Taguig"},
  {value: "Antipolo", label: "Antipolo"},
  {value: "Pasig", label: "Pasig"},
  {value: "Cagayan de Oro", label: "Cagayan de Oro"},
  {value: "Parañaque", label: "Parañaque"},
  {value: "Dasmariñas", label: "Dasmariñas"},
  {value: "Valenzuela", label: "Valenzuela"},
  {value: "Las Piñas", label: "Las Piñas"},
  {value: "General Santos", label: "General Santos"},
  {value: "Makati", label: "Makati"},
  {value: "Bacolod", label: "Bacolod"},
  {value: "Muntinlupa", label: "Muntinlupa"},
  {value: "San Jose del Monte", label: "San Jose del Monte"},
  {value: "Iloilo City", label: "Iloilo City"},
  {value: "Pasay", label: "Pasay"},
  {value: "Malabon", label: "Malabon"},
  {value: "Mandaluyong", label: "Mandaluyong"},
  {value: "Lapu-Lapu", label: "Lapu-Lapu"},
  {value: "Baguio", label: "Baguio"},
  {value: "Iligan", label: "Iligan"},
  {value: "Tagum", label: "Tagum"},
  {value: "Olongapo", label: "Olongapo"},
  {value: "Tarlac City", label: "Tarlac City"},
  {value: "Butuan", label: "Butuan"},
  {value: "Batangas City", label: "Batangas City"},
  {value: "Cotabato City", label: "Cotabato City"},
  {value: "Navotas", label: "Navotas"},
  {value: "Angeles City", label: "Angeles City"},
  {value: "Tuguegarao", label: "Tuguegarao"},
  {value: "Lucena", label: "Lucena"},
  {value: "Baybay", label: "Baybay"},
  {value: "Dagupan", label: "Dagupan"},
  {value: "Naga", label: "Naga"},
  {value: "San Pablo", label: "San Pablo"},
  {value: "Ormoc", label: "Ormoc"},
  {value: "Gapan", label: "Gapan"},
  {value: "Calapan", label: "Calapan"},
  {value: "Puerto Princesa", label: "Puerto Princesa"},
  {value: "Trece Martires", label: "Trece Martires"},
  {value: "Santiago", label: "Santiago"},
  {value: "Baler", label: "Baler"},
  {value: "Gingoog", label: "Gingoog"},
  {value: "Pagadian", label: "Pagadian"},
  {value: "Surigao", label: "Surigao"},
  {value: "Kidapawan", label: "Kidapawan"},
  {value: "Valencia", label: "Valencia"},
  {value: "Ligao", label: "Ligao"},
  {value: "Bais", label: "Bais"},
  {value: "Roxas City", label: "Roxas City"},
  {value: "Masbate City", label: "Masbate City"},
];
