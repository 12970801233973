import ReactDOM from "react-dom/client";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {msalConfig} from "./config/authConfig";
import "./index.css";
import App from "./App";

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <MsalProvider instance={msalInstance}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </MsalProvider>
);
