import {Link} from "react-router-dom";
import UFOImage from "../../assets/img/404/ufo-image.png";
import LargestRubyStone from "../../assets/img/404/largest-ruby-stone.png";
import MidRubyStone from "../../assets/img/404/mid-ruby-stone.png";
import SmallestRubyStone from "../../assets/img/404/smallest-ruby-stone.png";
import LargestPlanet from "../../assets/img/404/largest-planet-image.png";
import SmallestSaturn from "../../assets/img/404/smallest-saturn-image.png";
import LargestSaturn from "../../assets/img/404/largest-saturn-image.png";
import Planet from "../../assets/img/404/planet-image.png";

const RewardsPage = () => {
  return (
    <div id="login-page-container">
      <img alt="Ufo" className="ufo-image" src={UFOImage} />
      <img
        alt="Medium Ruby"
        className="mid-ruby-stone-image"
        src={MidRubyStone}
      />
      <img
        alt="Smallest Ruby"
        className="smallest-ruby-stone-image"
        src={SmallestRubyStone}
      />
      <img
        alt="Largest Stone"
        className="largest-ruby-stone-image"
        src={LargestRubyStone}
      />
      <img
        alt="Largest Planet"
        className="largest-planet-image"
        src={LargestPlanet}
      />
      <img alt="Saturn" className="largest-saturn-image" src={LargestSaturn} />
      <img
        alt="Small Saturn"
        className="smallest-saturn-image"
        src={SmallestSaturn}
      />
      <img alt="Planet" className="planet-image" src={Planet} />
      <div className="page-under-construction-main-container">
        <h1>Coming Soon</h1>
        <p>Stay Tuned for Exciting Updates!</p>
        <Link to="/">Back to Home</Link>
      </div>
    </div>
  );
};

export default RewardsPage;
