import {jwtDecode} from "jwt-decode";
import axiosInstance from "./axiosInstance";
import {PostCardFooterProps} from "../types/ComponentProps";

const likeAPost = async ({post: {postId}}: PostCardFooterProps) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  const userDetails = jwtDecode(
    JSON.stringify(localStorage.getItem("accessTokenGC"))
  );
  try {
    const response = await axiosInstance.patch(
      `/api/likePost/${postId}`,
      {likes: userDetails},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Error creating user");
  }
};

const unLikeAPost = async ({post: {postId}}: PostCardFooterProps) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  const userDetails = jwtDecode(
    JSON.stringify(localStorage.getItem("accessTokenGC"))
  );
  try {
    const response = await axiosInstance.patch(
      `/api/unLikePost/${postId}`,
      {likes: userDetails},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Error creating user");
  }
};

export {likeAPost, unLikeAPost};
