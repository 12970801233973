import {useState, useEffect, useRef} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import updatePost from "../../api/updatePost";
import {EditPostModalProps} from "../../types/ComponentProps";
import EditPostModalStyles from "./EditPostModalStyles.module.css";
import useGetPostById from "../../hooks/useGetPostById";
import Spinner from "../spinner/Spinner";
import useGetAllPostsById from "../../hooks/useGetAllPostsById";

type FormPostUpdateProps = {
  message?: string;
  imagePost?: File | null;
};

const EditPostModal = ({
  setEditPostModal,
  editCardSelection,
}: EditPostModalProps) => {
  const queryClient = useQueryClient();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const {getPostByIdData, isLoading} = useGetPostById(editCardSelection ?? 0);
  const [onUpdateLoading, setOnUpdateLoading] = useState<boolean>(false);
  const [imageOnBackEnd, setImageOnBackend] = useState(null);

  const {refetchGetAllPostsByIdData} = useGetAllPostsById(
    editCardSelection ?? 0
  );

  const [formPost, setFormPost] = useState<FormPostUpdateProps>({
    message: "",
    imagePost: null,
  });

  useEffect(() => {
    if (getPostByIdData?.post?.message && getPostByIdData?.post?.imageURL) {
      setFormPost({message: getPostByIdData.post.message});
      setImageOnBackend(getPostByIdData?.post?.imageURL);
    }
  }, [getPostByIdData]);

  const updatePostMutation = useMutation({
    mutationFn: () => updatePost(Number(editCardSelection ?? 0), formPost),
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({
          queryKey: ["getAllPosts"],
        });
        await queryClient.invalidateQueries({
          queryKey: ["getPostById"],
        });
      } catch (error: any) {
        throw new Error(error);
      } finally {
        if (setEditPostModal && setOnUpdateLoading) {
          await queryClient.invalidateQueries({
            queryKey: ["getAllPostsById"],
          });
          await refetchGetAllPostsByIdData();
          setOnUpdateLoading(false);
          setEditPostModal(false);
        }
      }
    },
    onError: (error: Error) => {
      console.error("Error create database:", error);
    },
  });

  const onCloseClick = () => {
    if (setEditPostModal) {
      setEditPostModal(false);
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {name, value} = e.target;
    setFormPost((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onUpdateSubmitHandler = () => {
    setOnUpdateLoading(true);
    updatePostMutation.mutate();
  };

  // Image Handlers
  const imageUploadHandler = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (validImageTypes.includes(file.type)) {
        if (setFormPost) {
          setFormPost((previous) => ({
            ...previous,
            imagePost: file,
          }));
        }
        const imageUrl = URL.createObjectURL(file);
        setImagePreview(imageUrl);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return () => URL.revokeObjectURL(imageUrl);
      } else {
        if (setFormPost) {
          setFormPost((previous) => ({
            ...previous,
            imagePost: null,
          }));
        }
      }
    }
  };

  if (isLoading) {
    return (
      <div className={EditPostModalStyles.updatePostModalContainer}>
        <div className={EditPostModalStyles.updatePostModalMain}>
          <Spinner variant="medium-spinner" alignSpin="center-spinner" />
        </div>
      </div>
    );
  }

  return (
    <div className={EditPostModalStyles.updatePostModalContainer}>
      <div className={EditPostModalStyles.updatePostModalMain}>
        <i className="fa-solid fa-xmark" onClick={onCloseClick}></i>
        <div>
          <h1>Edit Post</h1>
        </div>
        <textarea
          className={EditPostModalStyles.textAreaSectionBlk}
          name="message"
          onChange={onChangeHandler}
          value={formPost.message}
          placeholder={getPostByIdData?.post?.message || "Enter your message"}
          spellCheck="false"
          maxLength={1024}
        />

        {imagePreview || getPostByIdData?.post?.imageURL ? (
          <div className={EditPostModalStyles.imageSectionEditPost}>
            <div className={EditPostModalStyles.imageSectionEditPostFlexBox}>
              <div>
                <img
                  src={imagePreview || getPostByIdData?.post?.imageURL}
                  alt=""
                />
              </div>
            </div>
          </div>
        ) : null}

        <hr />
        <div className={EditPostModalStyles.footerBlkUpdateSection}>
          <div>
            <div className={EditPostModalStyles.footerBlkUpdateSectionIcons}>
              <div
                className={EditPostModalStyles.imageButtonContainer}
                onClick={imageUploadHandler}>
                <span className="fa-regular fa-image"></span>
                <p>{imageOnBackEnd ? "Upload new photo" : "upload photo"}</p>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                name="imagePost"
                style={{display: "none"}}
                onChange={handleFileChange}
              />
            </div>
          </div>
          <div>
            <button onClick={onUpdateSubmitHandler}>
              {onUpdateLoading ? (
                <Spinner
                  variant="extra-small-spinner"
                  alignSpin="center-spinner"
                />
              ) : (
                "Update Post"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPostModal;
