import axiosInstance from "./axiosInstance";

const getMultipleBadgesByIds = async (badgeIds: number[]) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  if (!Array.isArray(badgeIds) || badgeIds.length === 0) {
    throw new Error("Invalid input. userIds must be a non-empty array.");
  }
  try {
    const {data} = await axiosInstance.get("/api/getMultipleBadgesByIds", {
      params: {badgeIds: badgeIds.join(",")},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (error) {
    throw new Error(
      "Error fetching profile pictures: " + (error as Error).message
    );
  }
};

export default getMultipleBadgesByIds;
