import axiosInstance from "./axiosInstance";

const getUserDetailsById = async (userId: number) => {
  try {
    const {data} = await axiosInstance.get(`/api/user/${userId}`);
    return data;
  } catch (error) {
    throw new Error("Error fetching user points.");
  }
};

export default getUserDetailsById;
