import {useQuery} from "@tanstack/react-query";
import getProfilePhoto from "../api/getProfilePhoto";

const useGetProfilePicture = (userId: number) => {
  const {
    data: getProfileImage,
    isLoading,
    isError,
    error,
    isFetching,
    refetch: refetchProfileImage,
  } = useQuery({
    queryKey: ["getProfilePicture", userId],
    queryFn: () => getProfilePhoto(userId),
    enabled: userId > 0,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
  return {
    isLoading,
    getProfileImage,
    refetchProfileImage,
    isError,
    isFetching,
    error,
  };
};

export default useGetProfilePicture;
