import {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {PostCardDropDownProps} from "../../types/ComponentProps";
import PostCardStyles from "./PostCardStyles.module.css";
import deletePost from "../../api/deletePost";
import Modal from "../modal/Modal";
import useGetPostById from "../../hooks/useGetPostById";

const PostCardDropDown = ({
  postId,
  setEditPostModal,
  setEditCardSelection,
}: PostCardDropDownProps) => {
  const queryClient = useQueryClient();
  const {getPostByIdData} = useGetPostById(postId);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const deletePostMutation = useMutation({
    mutationFn: () => deletePost(postId),
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({queryKey: ["getAllPosts"]});
        await queryClient.invalidateQueries({
          queryKey: ["getAllPostsById"],
        });
        setDeleteLoading(false);
        setDeleteModal(false);
      } catch (error) {
        setDeleteLoading(false);
        console.error("Error invalidating queries:", error);
      }
    },
    onError: (error: Error) => {
      console.error("Error creating user:", error);
    },
  });

  const onClickHandler = () => {
    setShowDropDown(!showDropDown);
  };

  const onEditHandler = () => {
    if (setEditPostModal && setEditCardSelection) {
      setEditCardSelection(postId);
      setEditPostModal(true);
    }
  };

  const openModal = () => {
    setDeleteModal(true);
  };

  const onDeleteHandler = async () => {
    setDeleteLoading(true);
    deletePostMutation.mutate();
  };

  return (
    <>
      <Modal
        title="Are you sure you want to delete?"
        modalOpen={deleteModal}
        setDeleteModal={setDeleteModal}
        onSubmitHandler={onDeleteHandler}
        isLoading={deleteLoading}
      />

      <div
        onMouseLeave={() => setShowDropDown(false)}
        className={PostCardStyles.dropDownContainer}>
        {showDropDown && (
          <div className={PostCardStyles.dropDownBlk}>
            {getPostByIdData?.post?.badgeId ? null : (
              <p className={PostCardStyles.editButton} onClick={onEditHandler}>
                Edit
              </p>
            )}
            <p className={PostCardStyles.deleteButton} onClick={openModal}>
              Delete
            </p>
          </div>
        )}

        <i
          className={`${PostCardStyles.ellipsisBlk} fa-solid fa-ellipsis`}
          onClick={onClickHandler}
        />
      </div>
    </>
  );
};

export default PostCardDropDown;
