import {LevelBlockProps} from "../../types/ComponentProps";
import SummaryStyles from "./SummaryStyles.module.css";
import GiveableIcon from "../../assets/img/dashboard/giveable-icon.png";

const GiveablePoints = ({data}: LevelBlockProps) => {
  return (
    <div className={SummaryStyles.giveablePointsBlk}>
      <img alt="Giveable Icon" src={GiveableIcon} />
      <p>Giveable Points</p>
      <span>{data?.points?.giveablePoints}</span>
    </div>
  );
};

export default GiveablePoints;
