import SummaryStyles from "./SummaryStyles.module.css";
import RedeemableIcon from "../../assets/img/dashboard/redeemable-icon.png";
import {LevelBlockProps} from "../../types/ComponentProps";

const RedeemablePoints = ({data}: LevelBlockProps) => {
  return (
    <div className={`${SummaryStyles.redeemablePointsBlk}`}>
      <img alt="Redeem Icon" src={RedeemableIcon} />
      <p>Total Points Earned</p>
      <span>{data?.points?.points}</span>
    </div>
  );
};

export default RedeemablePoints;
