import {useState, useEffect} from "react";
import {useInView} from "react-intersection-observer";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import useGetAllHashTag from "../../hooks/useGetAllHashTag";
import Spinner from "../spinner/Spinner";
import deleteHashTag from "../../api/deleteHashTag";
import updateHashTagDetail from "../../api/updateHashTagDetail";
import {HashTagListProps, HashTagPage} from "../../types/ComponentProps";
import ModalIcon from "../../assets/img/dashboard/modal-icon.png";
import HashTagListStyles from "./HashTagListStyles.module.css";
import CreateHashTagModal from "../hashTagModal/createHashTagModal";

const HashTagList = () => {
  const initialState = {
    hashTagName: "",
  };
  const queryClient = useQueryClient();
  const {ref, inView} = useInView();
  const {
    getAllDataHashTag,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetAllHashTag();

  const [createHashTagModal, setCreateHashTagModal] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedHashTag, setSelectedHashTag] = useState<number | null>(null);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [onDeleteLoading, setOnDeleteLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState<number | null>(null);
  const [editInput, setEditInput] = useState(initialState);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const hashTags =
    getAllDataHashTag?.pages.flatMap((page: HashTagPage) => page.hashTags) ||
    [];

  // Delete Mutation
  const deleteHashTagMutation = useMutation({
    mutationFn: () => deleteHashTag(selectedHashTag ?? 0),
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({queryKey: ["getAllHashTag"]});
      } catch (error) {
        console.error("Error invalidating queries:", error);
      } finally {
        setDeleteModal(false);
        setOnDeleteLoading(false);
        setSelectedHashTag(null);
      }
    },
    onError: (error: Error) => {
      console.error("Error comment deletion:", error);
    },
  });

  // Edit Mutation
  const editHashTagMutation = useMutation({
    mutationFn: () => updateHashTagDetail(isEditing ?? 0, editInput),
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({queryKey: ["getAllHashTag"]});
      } catch (error) {
        console.error("Error invalidating queries:", error);
      } finally {
        setIsEditing(null);
      }
    },
    onError: (error: Error) => {
      console.error("Error comment deletion:", error);
      setErrorMessage(error?.message);
    },
  });

  const onEditInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target;
    setEditInput((previous) => ({
      ...previous,
      [name]: value,
    }));
    setErrorMessage(null);
  };

  // Hashtag Create Handlers
  const onCreateHashTagClickHandler = () => {
    setCreateHashTagModal(true);
  };

  // HashTag Update Handlers
  const updateDropDownClickHandler = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const onHashTagEditHandler = (id: number, hashTagName: string) => {
    setSelectedHashTag(id);
    setIsEditing(id);
    setEditInput({hashTagName});
  };

  const updateButton = () => {
    if (!editInput?.hashTagName) return setErrorMessage("This can't be blank.");
    editHashTagMutation.mutate();
  };

  const cancelButton = () => {
    setIsEditing(null);
    setSelectedHashTag(null);
    setEditInput(initialState);
  };

  // Delete Handlers
  const onDeleteHandler = (id: number) => {
    setSelectedHashTag(id);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const onDeleteSubmitHandler = () => {
    setOnDeleteLoading(true);
    deleteHashTagMutation.mutate();
  };

  return (
    <>
      {deleteModal ? (
        <div className="delete-modal-blk">
          <div className="delete-modal-main-blk">
            <span
              className="fa-solid fa-xmark"
              onClick={closeDeleteModal}></span>
            <img
              src={ModalIcon}
              alt="Modal icon"
              className="delete-icon-modal-hashtaglist"
            />
            <h1>Are you sure you want to delete this hashTag?</h1>
            <button onClick={onDeleteSubmitHandler}>
              {onDeleteLoading ? (
                <Spinner variant="small-spinner" alignSpin="center-spinner" />
              ) : (
                "YES"
              )}
            </button>
          </div>
        </div>
      ) : null}

      {createHashTagModal && (
        <CreateHashTagModal setCreateHashTagModal={setCreateHashTagModal} />
      )}

      <div className={HashTagListStyles.manageHashTagListBlk}>
        <div className={HashTagListStyles.manageHashtagBlkTitle}>
          <h1>Manage HashTags</h1>
          <button
            className={HashTagListStyles.manageHashtagBlkTitleButton}
            onClick={onCreateHashTagClickHandler}>
            <i className="fa-solid fa-plus"></i>
            Create HashTag
          </button>
        </div>
        <div className={HashTagListStyles.headerTableBlkHeaderHashtaglist}>
          <div>
            <p>HashTag id</p>
          </div>
          <div>
            <p>HashTag Name</p>
          </div>
          <div>
            <p style={{marginLeft: "-5px"}}>HashTag Counter</p>
          </div>
          <div>
            <p>Actions</p>
          </div>
        </div>

        <div className={HashTagListStyles.hashTagListMainContainer}>
          {isLoading ? (
            <div style={{display: "block", padding: "10px 0"}}>
              <Spinner variant="medium-spinner" alignSpin="center-spinner" />
            </div>
          ) : hashTags.length === 0 ? (
            <p className={HashTagListStyles.notAvailableBlkTxt}>
              No HashTags are currently available
            </p>
          ) : (
            <div className={HashTagListStyles.rowBlkHashtaglistContainer}>
              {hashTags.map((data: HashTagListProps, index: number) => (
                <div
                  className={HashTagListStyles.rowBlkHashtaglist}
                  key={data.hashTagId}>
                  <div>
                    <p>{data.hashTagId}</p>
                  </div>
                  <div>
                    {isEditing === data.hashTagId && !deleteModal ? (
                      <>
                        <div className={HashTagListStyles.updateFormBlkSection}>
                          <input
                            type="text"
                            name="hashTagName"
                            value={editInput.hashTagName}
                            placeholder={data.hashTagName}
                            onChange={onEditInputChangeHandler}
                            autoComplete="off"
                          />
                          <div
                            className={
                              HashTagListStyles.editDeleteBtnHashtaglist
                            }>
                            <button onClick={updateButton}>Update</button>
                            <button onClick={cancelButton}>Cancel</button>
                          </div>
                        </div>
                        <p
                          className={
                            HashTagListStyles.errorMessageUpdateHashtagList
                          }>
                          {errorMessage ? errorMessage : null}
                        </p>
                      </>
                    ) : (
                      <p>#{data.hashTagName}</p>
                    )}
                  </div>
                  <div>
                    <p>{data.postCount}</p>
                  </div>
                  <div style={{position: "relative"}}>
                    {activeIndex === index && (
                      <div
                        className={HashTagListStyles.updateDropDownBlk}
                        onMouseLeave={() => setActiveIndex(null)}>
                        <p
                          onClick={() =>
                            onHashTagEditHandler(
                              data.hashTagId ?? 0,
                              data?.hashTagName ?? ""
                            )
                          }>
                          Edit
                        </p>
                        <p onClick={() => onDeleteHandler(data.hashTagId ?? 0)}>
                          Delete
                        </p>
                      </div>
                    )}
                    <i
                      className={`fa-solid fa-ellipsis ${HashTagListStyles.optionsIcon}`}
                      onClick={() => updateDropDownClickHandler(index)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          {isFetchingNextPage && !isLoading ? (
            <div style={{display: "block", padding: "10px 0"}}>
              <Spinner variant="medium-spinner" alignSpin="center-spinner" />
            </div>
          ) : null}
          <div ref={ref}></div>
        </div>
      </div>
    </>
  );
};

export default HashTagList;
