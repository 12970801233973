import {Dispatch, SetStateAction} from "react";
import {Socket} from "socket.io-client";
import Logo from "../../components/logo/Logo";
import AdminLinks from "../adminLinks/AdminLinks";
import ProfileNav from "../profileNav/ProfileNav";
import NavigationStyles from "./Navigation.module.css";

type NavigationProps = {
  socket?: Socket | null;
  setNotificationModal?: Dispatch<SetStateAction<boolean>>;
  setSelectedPostNotification?: Dispatch<SetStateAction<number | null>>;
  setSelectedNotificationId?: Dispatch<SetStateAction<number | null>>;
};

const Navigation = ({
  socket,
  setNotificationModal,
  setSelectedPostNotification,
  setSelectedNotificationId,
}: NavigationProps) => {
  return (
    <div className={`${NavigationStyles.navigation}`}>
      <div className="main-container-blk">
        <div className={`flex-box-blk ${NavigationStyles.flexBoxBlk}`}>
          <Logo />
          <AdminLinks />
          <ProfileNav
            socket={socket}
            setNotificationModal={setNotificationModal}
            setSelectedPostNotification={setSelectedPostNotification}
            setSelectedNotificationId={setSelectedNotificationId}
          />
        </div>
      </div>
    </div>
  );
};

export default Navigation;
