import {useQuery} from "@tanstack/react-query";
import getUserPoint from "../api/getUserPoint";

const useGetUserPoint = () => {
  const {
    data,
    isLoading,
    isError,
    error,
    refetch: refetchGetUserPoint,
  } = useQuery({
    queryKey: ["getUserPoint"],
    queryFn: getUserPoint,
    refetchOnWindowFocus: false,
  });
  return {isLoading, data, refetchGetUserPoint, isError, error};
};

export default useGetUserPoint;
