import axiosInstance from "./axiosInstance";

const getMultipleProfilePicture = async (userIds: number[]) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  if (!Array.isArray(userIds) || userIds.length === 0) {
    throw new Error("Invalid input. userIds must be a non-empty array.");
  }

  try {
    const {data} = await axiosInstance.get("/api/profileImagesByIds", {
      params: {userIds: userIds.join(",")},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (error) {
    throw new Error(
      "Error fetching profile pictures: " + (error as Error).message
    );
  }
};

export default getMultipleProfilePicture;
