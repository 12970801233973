import {useNavigate} from "react-router-dom";
import {useNotification} from "../../context/NotificationContext";
import WithNavTemplate from "../../components/template/WithNavTemplate";
import MyProfile from "../../components/myProfile/MyProfile";
import Navigation from "../../components/navigation/Navigation";
import Spinner from "../../components/spinner/Spinner";

const ProfilePage = () => {
  const {
    socketState,
    setNotificationModal,
    setSelectedPostNotification,
    setSelectedNotificationId,
  } = useNotification();
  const navigate = useNavigate();
  const navigationOnClick = () => {
    navigate("/");
  };

  if (!socketState) {
    return (
      <>
        <Navigation />
        <div className="inside-page-container-blk">
          <div
            className="main-container-blk"
            style={{
              position: "relative",
              height: "100vh",
              paddingTop: "200px",
            }}>
            <Spinner alignSpin="center-spinner" variant="large-spinner" />
          </div>
        </div>
      </>
    );
  }

  return (
    <WithNavTemplate
      socket={!socketState ? null : socketState}
      setNotificationModal={setNotificationModal}
      setSelectedPostNotification={setSelectedPostNotification}
      setSelectedNotificationId={setSelectedNotificationId}>
      <div className="inside-page-container-blk profile-main-container">
        <div className="main-container-blk ">
          <div className="page-title-blk">
            <i
              onClick={navigationOnClick}
              className="fa-solid fa-arrow-left arrow-back-page"></i>
            <h1>My Profile</h1>
          </div>
          <MyProfile />
        </div>
      </div>
    </WithNavTemplate>
  );
};

export default ProfilePage;
