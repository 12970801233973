import ProgressBarStyles from "./ProgressBarStyles.module.css";
import {ProgressBarProps} from "../../types/ComponentProps";

const ProgressBar = ({width, points}: ProgressBarProps) => {
  return (
    <div className={ProgressBarStyles.progressBarContainer}>
      <div>
        <div style={{width: `${width}%`}}></div>
      </div>
      <span>{points}</span>
    </div>
  );
};

export default ProgressBar;
