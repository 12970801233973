import axios from "axios";
import axiosInstance from "./axiosInstance";
import {CreateHashTagFormProps} from "../types/ComponentProps";

const updateHashTagDetail = async (
  hashTagId: number,
  hashTagDetails: CreateHashTagFormProps
) => {
  const accessToken = localStorage.getItem("accessTokenGC");

  try {
    const response = await axiosInstance.patch(
      `/api/hashTagUpdate/${hashTagId}`,
      {
        hashTagName: hashTagDetails?.hashTagName,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message =
        error.response?.data?.message || "An unexpected error occurred.";
      throw new Error(message);
    } else {
      throw new Error("An unexpected error occurred.");
    }
  }
};

export default updateHashTagDetail;
