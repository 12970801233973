import SaturnImage from "../../assets/img/dashboard/trending-saturn-image.png";
import useGetAllHashTagList from "../../hooks/useGetAllHashTagList";
import Spinner from "../spinner/Spinner";
import {HashTagListProps} from "../../types/ComponentProps";
import HashTagBlockStyles from "./HashTagBlockStyles.module.css";

const HashTagBlock = () => {
  const {getAllHashTagListData, isLoadingHashTagListData} =
    useGetAllHashTagList();

  return (
    <div className={HashTagBlockStyles.hashTageBlockSection}>
      <img
        className={HashTagBlockStyles.saturnImage}
        src={SaturnImage}
        alt="Saturn"
      />
      <div className={HashTagBlockStyles.hashTagMainSectionBlock}>
        <h1>Company DNA</h1>
        {isLoadingHashTagListData ? (
          <Spinner
            variant="medium-spinner"
            addedStyles={{marginTop: "20px"}}
            alignSpin="center-spinner"
          />
        ) : (
          getAllHashTagListData?.hashTagList.map(
            (data: HashTagListProps, index: number) => (
              <div key={index} className={HashTagBlockStyles.hashTagListBlock}>
                <h3>#{data?.hashTagName}</h3>
                <h2>{data?.postCount} Mentions</h2>
              </div>
            )
          )
        )}
      </div>
    </div>
  );
};

export default HashTagBlock;
