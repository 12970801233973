import axiosInstance from "./axiosInstance";

const updateComment = async (commentId: number, message: string) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const response = await axiosInstance.patch(
      `/api/comment/${commentId}`,
      {message: message},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Error updating comment");
  }
};

export default updateComment;
