import {useQuery} from "@tanstack/react-query";
import getMultipleProfilePicture from "../api/getMultipleProfilePicture";

const useGetMultipleProfilePicture = (userIds: number[]) => {
  const {
    data: multipleProfilePictureData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["getMultipleProfilePictures", userIds],
    queryFn: () => getMultipleProfilePicture(userIds),
    refetchOnWindowFocus: false,
    enabled: userIds.length > 0,
  });

  return {isLoading, multipleProfilePictureData, isError, error};
};

export default useGetMultipleProfilePicture;
