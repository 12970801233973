import React, {useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";
import {useInView} from "react-intersection-observer";
import useGetLikedPostsByUserId from "../../hooks/useGetLikedPostsByUserId";
import AdminPostCard from "../postCard/AdminPostCard";
import Spinner from "../spinner/Spinner";
import PostCard from "../postCard/PostCard";
import {PostCardDetailsProps, TokenPayload} from "../../types/ComponentProps";
import LikesModal from "../likesModal/LikesModal";

const LikeTab = () => {
  const {ref, inView} = useInView();
  const userDetails = jwtDecode<TokenPayload>(
    JSON.stringify(localStorage.getItem("accessTokenGC"))
  );
  const {
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    hasNextPage,
    userLikedPosts,
  } = useGetLikedPostsByUserId(Number(userDetails?.id));

  const [selectedCardModal, setSelectedCardModal] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<any>(null);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  return (
    <>
      {selectedCardModal ? (
        <LikesModal
          post={selectedCard}
          setSelectedCardModal={setSelectedCardModal}
        />
      ) : null}
      {isLoading ? (
        <Spinner alignSpin="center-spinner" variant="medium-spinner" />
      ) : userLikedPosts?.pages[0]?.posts.length === 0 ? (
        <p className="not-available-blk">No posts available</p>
      ) : (
        userLikedPosts?.pages.map((page, index) => (
          <React.Fragment key={index}>
            {page.posts.map((post: PostCardDetailsProps, index: number) =>
              post?.badgeId ? (
                <AdminPostCard key={index} post={post} />
              ) : (
                <PostCard
                  key={index}
                  post={post}
                  setSelectedCardModal={setSelectedCardModal}
                  setSelectedCard={setSelectedCard}
                />
              )
            )}
          </React.Fragment>
        ))
      )}
      <div ref={ref}>
        {isFetchingNextPage ? (
          <Spinner alignSpin="center-spinner" variant="medium-spinner" />
        ) : hasNextPage ? null : !isLoading &&
          userLikedPosts?.pages?.[0]?.posts?.length !== 0 ? (
          <div className="all-posts-label-blk">
            <div></div>
            <p>You have seen all the posts</p>
            <div></div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default LikeTab;
