import {useState, useEffect} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import Spinner from "../spinner/Spinner";
import ModalIcon from "../../assets/img/dashboard/modal-icon.png";
import deleteComment from "../../api/deleteComment";
import {jwtDecode} from "jwt-decode";
import {
  CommentModalUpdateProps,
  AccessTokenProps,
} from "../../types/ComponentProps";
import CommentsModalStyles from "./CommentsModalStyles.module.css";

const CommentListUpdate = ({
  post,
  onEditClickHandler,
}: CommentModalUpdateProps) => {
  const queryClient = useQueryClient();
  const [dropDown, setDropDown] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const accessToken = localStorage.getItem("accessTokenGC");
  const [jwtDecodeToken, setJwtDecodedToken] = useState<AccessTokenProps>(null);

  useEffect(() => {
    if (accessToken) {
      try {
        setJwtDecodedToken(jwtDecode(accessToken));
      } catch (error) {
        console.error("Invalid token:", error);
      }
    }
  }, [accessToken]);

  const deleteCommentMutation = useMutation({
    mutationFn: () => deleteComment(post?.commentId ?? 0),
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({queryKey: ["getPostComments"]});
        setDeleteLoading(false);
      } catch (error) {
        console.error("Error invalidating queries:", error);
      }
    },
    onError: (error: Error) => {
      console.error("Error comment deletion:", error);
    },
  });

  const onClickHandler = () => {
    setDropDown(!dropDown);
  };

  const onEditHandler = () => {
    if (onEditClickHandler) {
      onEditClickHandler();
    }
  };

  const onDeleteHandler = () => {
    setModal(true);
  };

  const closeHandler = () => {
    return setModal(false);
  };

  const onDeleteSubmitHandler = () => {
    setDeleteLoading(true);
    deleteCommentMutation.mutate();
  };

  return (
    <>
      {modal && (
        <div className={CommentsModalStyles.deletePrompt}>
          <div className={CommentsModalStyles.deletePromptMainContainer}>
            <span className="fa-solid fa-xmark" onClick={closeHandler}></span>
            <img
              src={ModalIcon}
              alt="Modal icon"
              className={CommentsModalStyles.deleteIcon}
            />
            <h1>Are you sure you want to delete this comment?</h1>
            <button onClick={onDeleteSubmitHandler}>
              {deleteLoading ? (
                <Spinner variant="small-spinner" alignSpin="center-spinner" />
              ) : (
                "YES"
              )}
            </button>
          </div>
        </div>
      )}
      <div className={CommentsModalStyles.ellipsisSection}>
        {dropDown && (
          <div
            onMouseLeave={() => setDropDown(false)}
            className={CommentsModalStyles.dropDownEllipsisSection}>
            <p onClick={onEditHandler}>Edit</p>
            <p onClick={onDeleteHandler}>Delete</p>
          </div>
        )}
        {jwtDecodeToken?.id !== post?.userId &&
        !jwtDecodeToken?.isAdmin ? null : (
          <span
            onClick={onClickHandler}
            className="fa-solid fa-ellipsis"></span>
        )}
      </div>
    </>
  );
};

export default CommentListUpdate;
