import {AmountModalProps} from "../../types/ComponentProps";
import styles from "./AmoutButtonStyles.module.css";

const AmountModal = ({
  setFormPost,
  setAmountModal,
  giveablePoints,
}: AmountModalProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
    ];
    const isNumber = /^[0-9]$/.test(event.key);
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const onClickHandler =
    (increment: number) => (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault();

      setFormPost?.((previous) => ({
        ...previous,
        pointsGiven: increment,
      }));

      setAmountModal?.(false);
    };

  const onClickHandlerAmountInput = () => {
    setAmountModal?.(false);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    const sanitizedValue = value.replace(/\D/g, "");
    setFormPost?.((previous) => ({
      ...previous,
      pointsGiven: Number(sanitizedValue),
    }));
  };

  const onMouseLeaveModal = () => {
    if (setAmountModal) {
      setAmountModal(false);
    }
  };

  return (
    <div className={styles.modalContainer} onMouseLeave={onMouseLeaveModal}>
      <div className={styles.flexBoxBlk}>
        {[5, 10, 15, 20, 25].map((amount) => (
          <span key={amount} onClick={onClickHandler(amount)}>
            +{amount}
          </span>
        ))}
      </div>
      <div className={styles.flexBoxBlkAmount}>
        <input
          className={styles.inputAmount}
          type="text"
          placeholder="Enter specific amount"
          maxLength={6}
          onChange={handleAmountChange}
          onKeyDown={handleKeyDown}
        />
        <button onClick={onClickHandlerAmountInput}>OK</button>
      </div>

      <p>{giveablePoints} Points available to give</p>
    </div>
  );
};

export default AmountModal;
