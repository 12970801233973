import {useQuery} from "@tanstack/react-query";
import getPostById from "../api/getPostById";

const useGetPostById = (postId: number) => {
  const {
    data: getPostByIdData,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ["getPostById", postId],
    queryFn: () => getPostById(postId),
    refetchOnWindowFocus: false,
    enabled: postId > 0,
  });

  return {isLoading, getPostByIdData, isError, error, refetch};
};

export default useGetPostById;
