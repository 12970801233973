import axios from "axios";
import axiosInstance from "./axiosInstance";

const deleteNotification = async (notificationId: number) => {
  const accessToken = localStorage.getItem("accessTokenGC");

  if (!accessToken) {
    throw new Error("No access token found");
  }

  try {
    const response = await axiosInstance.delete(
      `/api/deleteNotificationById/${notificationId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: unknown) {
    console.error("Error response:", error);
    let errorMessage = "Error deleting Notification. Try again.";

    if (axios.isAxiosError(error)) {
      errorMessage = error.response?.data?.message || errorMessage;
    }

    throw new Error(errorMessage);
  }
};

export default deleteNotification;
