import React, {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import Message from "../message/Message";
import createHashTag from "../../api/createHashTag";
import Input from "../input/Input";
import {
  CreateHashTagModalProps,
  CreateHashTagFormProps,
} from "../../types/ComponentProps";
import HashTagStyles from "./HashTagStyles.module.css";
import Spinner from "../spinner/Spinner";

const CreateHashTagModal = ({
  setCreateHashTagModal,
}: CreateHashTagModalProps) => {
  const initialState: CreateHashTagFormProps = {
    hashTagName: "",
  };

  const [hashTagForm, setHashTagForm] =
    useState<CreateHashTagFormProps>(initialState);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const createHashTagMutation = useMutation({
    mutationFn: () => createHashTag(hashTagForm),
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({queryKey: ["getAllHashTag"]});
        if (setCreateHashTagModal) {
          setCreateHashTagModal(false);
        }
      } catch (error) {
        setErrorMessage("An error occurred while updating the hash tags.");
      } finally {
        setIsLoading(false);
      }
    },
    onError: (error: Error) => {
      console.error("Error creating hash tag:", error.message);
      setErrorMessage(
        error.message || "An error occurred while creating the hash tag."
      );
      setIsLoading(false);
    },
  });

  const onCreateHashTagClickHandler = () => {
    if (!hashTagForm.hashTagName) {
      return setErrorMessage("HashTag name is required.");
    }
    setIsLoading(true);
    createHashTagMutation.mutate();
  };

  const closeHandler = () => {
    if (setCreateHashTagModal) {
      setCreateHashTagModal(false);
    }
  };

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = e.target;
    setHashTagForm((prev: CreateHashTagFormProps) => ({
      ...prev,
      [name]: value,
    }));
    setErrorMessage(null);
  };

  return (
    <div className={HashTagStyles.createHashTagModal}>
      <div className={HashTagStyles.createHashTagPromptMainContainer}>
        <div className={HashTagStyles.flexBoxBlk}>
          <div>
            <h1>Create HashTag</h1>
          </div>
          <div>
            <span className="fa-solid fa-xmark" onClick={closeHandler}></span>
          </div>
        </div>
        <hr className={HashTagStyles.horizontalLine} />
        <div className={HashTagStyles.basicSettingsFlexBoxBlk}>
          <div>
            <Input
              type="text"
              value={hashTagForm.hashTagName}
              name="hashTagName"
              onChangeHandler={onChangeHandler}
              label="HashTag Name"
            />
            <p>Just enter the hashtag name without the # symbol.</p>
          </div>
        </div>
        {errorMessage && (
          <div style={{marginTop: "10px"}}>
            <Message variant="error">{errorMessage}</Message>
          </div>
        )}
        <button
          className={HashTagStyles.buttonCreateHashTag}
          onClick={onCreateHashTagClickHandler}>
          {isLoading ? (
            <Spinner
              variant="small-spinner"
              addedStyles={{paddingBottom: "10px"}}
              alignSpin="center-spinner"
            />
          ) : (
            <>
              Create HashTag<i className="fa-solid fa-arrow-right"></i>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default CreateHashTagModal;
