import {Outlet, Navigate} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {NotificationProvider} from "../context/NotificationContext";

const PrivateRoute = () => {
  const {accounts} = useMsal();
  const isAuthenticated = accounts.length > 0;
  const accessToken = localStorage.getItem("accessTokenGC");
  return isAuthenticated && accessToken ? (
    <NotificationProvider>
      <Outlet />
    </NotificationProvider>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
