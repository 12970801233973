import axiosInstance from "./axiosInstance";

const getUsers = async () => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const {data} = await axiosInstance.get("/api/users", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (error) {
    throw new Error("Error creating user");
  }
};

export default getUsers;
