import ProgressBar from "../progressBar/ProgressBar";
import Spinner from "../spinner/Spinner";
import useGetProfilePicture from "../../hooks/useGetProfilePicture";
import LeaderBoardStyles from "./LeaderBoardStyles.module.css";
import DefaultImage from "../../assets/img/default-profile-photo.png";
import {LeaderBoardCardProps} from "../../types/ComponentProps";
import {getLevel} from "../../utils/getLevel";

const LeaderboardCard = ({data}: LeaderBoardCardProps) => {
  const {
    points,
    user: {id, firstName, lastName},
  } = data;

  const {getProfileImage, error, isLoading, isFetching} =
    useGetProfilePicture(id);

  const levelName = getLevel(points);

  return (
    <div
      className={`${LeaderBoardStyles.leaderboardCard} leaderboard-card-blk`}>
      {isLoading || isFetching ? (
        <Spinner
          addedStyles={{marginTop: "6px"}}
          variant="extra-small-spinner"
          alignSpin="center-spinner"
        />
      ) : error ? (
        <img alt="" src={DefaultImage} />
      ) : (
        <img alt="" src={getProfileImage?.profileImage} />
      )}
      <div>
        <h3
          className={
            LeaderBoardStyles.nameSection
          }>{`${firstName} ${lastName}`}</h3>

        <ProgressBar
          width={points === 0 ? points : levelName?.percentage ?? 0}
          points={points}
        />
      </div>
    </div>
  );
};

export default LeaderboardCard;
