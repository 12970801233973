type SpinnerProps = {
  addedStyles?: {};
  variant?:
    | "large-spinner"
    | "medium-spinner"
    | "small-spinner"
    | "extra-small-spinner";
  alignSpin: "center-spinner" | "left-spinner" | "right-spinner";
};

const Spinner = ({variant, alignSpin, addedStyles}: SpinnerProps) => {
  return (
    <div
      style={addedStyles}
      className={`spinner-blk ${variant} ${alignSpin}`}></div>
  );
};

export default Spinner;
